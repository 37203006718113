import React, { useRef } from "react";
import { Button, DatePicker } from "antd";
import { PageContainer, PageHeader, PageContent } from "../Layout";
import netSettlementTable from "../MyTable/netSettlementTable";
import { Filter } from "../Design";
import styled from "styled-components";
import { fetchReportData, fetchReportFile } from "../../utils/reportRequest";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import PDFTable from "../pdfTables/netSettlementPDF";
import { pdf } from "@react-pdf/renderer";

export default ({ fileName, reportName }) => {
  const { RangePicker } = DatePicker;
  const { accessToken, user } = useSelector((state) => state.auth);
  const operatorCode=user?.operator?.code||"";

  const [data, setData]=React.useState();
  const tablePrintRef = useRef();

  const [filters, setFilters] = React.useState({
    startDate: "",
    endDate  : "",
  });

  const dateFilter = (e) => {
    if (e) {
      setFilters({
        ...filters,
        startDate: e && dayjs(e[0]).startOf("day").format("YYYY-MM-DDTHH:mm"),
        endDate: e && dayjs(e[1]).endOf("day").format("YYYY-MM-DDTHH:mm"),
      });
    } else {
      setFilters({
        ...filters,
        startDate: null,
        endDate: null,
      });
    }
  };

  const onClear = () => {
    setFilters({
      startDate: "",
      endDate  : "",
    });
    setData(null);
  };

  const getData = () => {
    fetchReportData("report/net_settlement", "POST", accessToken, {
      ...filters,
      operatorCode,
      excel: "false",
    })
      .then((e) => {
        if (e.responseCode) {
          setData(e.data);
        }
      })
      .catch((err) => {
        console.log("err ", err);
      });
  };
  
  const openPDFInNewWindow = async () => {
    if(data) {
      const blob = await pdf(
        <PDFTable
          filters={filters}
          data={data} 
          paricipment={user?.operator?.name ?? ""}
        />
      ).toBlob(); 

      const url = URL.createObjectURL(blob);
      const newWindow = window.open(url);

      if (newWindow) {
        newWindow.focus();
      } else {
        alert("Please allow popups for this website");
      };
    };
  };

  React.useEffect(() => {
    if (filters.startDate !== "" && filters.endDate !== "") {
      getData();
    }
  }, [filters]);
  
  return (
    <PageContainer>
      <PageHeader title={reportName} />
      <PageContent>
        <Filter
          extra={[
            <RangePicker 
              value={
                filters.startDate && filters.endDate
                  ? [dayjs(filters.startDate), dayjs(filters.endDate)]
                  : null
              } 
              onChange={dateFilter} 
            />,
            <Button onClick={onClear}>Цэвэрлэх</Button>,
            <Button
              style={{ backgroundColor: "green", color: "white" }}
              onClick={() => {
                fetchReportFile(
                  "report/net_settlement",
                  "POST",
                  accessToken,
                  {
                    ...filters,
                    operatorCode,
                    excel: "true"
                  },
                  `${fileName}_${user?.operator?.name ?? ""}`.toLowerCase()
                );
              }}
            >
              Татах
            </Button>,
            <Button onClick={data ? openPDFInNewWindow : null}>Open PDF</Button>
          ]}
        ></Filter>
        {data !== null && (
          <TableContainer ref={tablePrintRef}>
            <Table
              loading={true}
              filters={filters}
              // ref={ref}
              data={data}
              paricipment={user?.operator?.name ?? ""}
              rowKey={(record) => record?.rowKey}
              getData={getData}
            />
          </TableContainer>
        )}
      </PageContent>
    </PageContainer>
  );
};

const TableContainer = styled.div`
  margin-top: 20px;
  table {
    width: 100%;
    border-collapse: collapse;
  }

  th, td {
    padding: 10px;
    border: 1px solid #ddd;
  }

  th {
    background-color: #f4f4f4;
  }

  /* Print-specific styles */
  @media print {
    th, td {
      font-size: 12px;
      padding: 8px;
    }
    
    body {
      margin: 0;
      padding: 0;
      width: auto;
    }
    
    /* Hide certain elements during print */
    button {
      display: none;
    }

    .filter-container {
      display: none;
    }
  }
`;

const Table = styled(netSettlementTable)`
  .ant-table-cell {
    padding: 0px;
    line-height: 15px;
  }
  .ant-table-thead .ant-table-cell {
    padding: 8px;
  }
  .ant-table-summary .ant-table-cell {
    padding: 8px;
  }
  .header-cell {
    text-align: left;
    background-color: #f5f5f5;
    font-weight: bold;
  }
  .subheader-cell {
    padding: 8px;
    padding-left: 40px;
    font-weight: bold;
  }
  .subvalue-cell {
    padding: 8px;
    font-weight: bold;
  }
  .cell-center {
    text-align: center;
  }
`;
