import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const PublicRoute = ({ element }) => {
  const { accessToken } = useSelector((state) => state.auth);

  // If authenticated, redirect to the home page (or another authenticated route)
  if (accessToken) {
    return <Navigate to="/" replace />;
  }

  // Otherwise, render the passed element (public component)
  return element;
};

export default PublicRoute;
