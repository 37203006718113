import React from 'react';
import { Document, Page, Text, View, StyleSheet, Font } from '@react-pdf/renderer';
import source from "../../assets/fonts/Roboto-Regular.ttf";
import dayjs from 'dayjs';

Font.register({ family: 'Roboto', src: source });

const styles=StyleSheet.create({
  table: {
    margin: 20,
    display: "flex",
    border: "1px solid #000",
    flexDirection: "column",
    fontFamily: "Roboto",
  },
  cellRow: {
    flexDirection: "row",
  },
  subHeadCell:{
    textAlign: "center",
    width: "20%",
    border: "1px solid #000",
    fontSize: 12,
    padding: 5,
  },
  tableHeader: {
    flexDirection: "row",
    backgroundColor: "#f3f3f3",
    width: "100%",
  },
  tableRow: {
    flexDirection: "row",
    borderBottom: "1px solid #000",
    padding: 5,
  },
  cell: {
    width: "16.67%",
    flex: 1,
    padding: 5,
    fontSize: 10,
    textAlign: "center",
  },
  headerCell: {
    border: "1px solid #000",
    width: "100%",
    padding: 5,
    fontSize: 12,
    textAlign: "left",
    flexWrap: 'wrap',
    fontWeight: "bold",
  },
});

const PDFTable=( { data, operatorName } ) => {
  const incomingRow = [];
  const outgoingRow = [];

  if (data?.incoming?.data && data.incoming.data.lenght !== 0) {
    data.incoming.data.forEach(el => {
      const date = Object.keys(el)[0];
      el[date].forEach(elem => {
        incomingRow.push({
          transactionCode: elem.transactioncode,
          date: dayjs(elem.date).format('YYYY-MM-DD'),
          transactionBankCode: elem.transaction_bank_code,
          transactionBankAccountNumber: elem.transaction_bank_account_number,
          transactionAmount: elem.transactionAmount
        });
      });
      incomingRow.push({
        subDate: dayjs(date).format('YYYY-MM-DD'),
        dateTotal: el.total[0].total_amount,
      });
    });
  };

  if (data?.outgoing?.data && data.outgoing.data.lenght !== 0) {
    data.outgoing.data.forEach(el => {
      const date = Object.keys(el)[0];
      el[date].forEach(elem => {
        outgoingRow.push({
          transactionCode: elem.transactioncode,
          date: dayjs(elem.date).format('YYYY-MM-DD'),
          transactionBankCode: elem.transaction_bank_code,
          transactionBankAccountNumber: elem.transaction_bank_account_number,
          transactionAmount: elem.transactionAmount
        });
      });
      outgoingRow.push({
        subDate: dayjs(date).format('YYYY-MM-DD'),
        dateTotal: el.total[0].total_amount,
      });
    });
  };

  return (
    <Document>
      <Page size="A4">
        <View style={styles.table}>
          <View style={styles.cellRow}>
            <View style={styles.tableHeader}><Text style={styles.headerCell}>Харилцагч</Text></View>
            <View style={styles.tableHeader}><Text style={styles.headerCell}>Гүйлгээний дугаар</Text></View>
            <View style={styles.tableHeader}><Text style={styles.headerCell}>Огноо</Text></View>
            <View style={styles.tableHeader}><Text style={styles.headerCell}>Илгээсэн харилцагч</Text></View>
            <View style={styles.tableHeader}><Text style={styles.headerCell}>Илгээсэн данс дугаар</Text></View>
            <View style={styles.tableHeader}><Text style={styles.headerCell}>Гүйлгээний дүн</Text></View>
          </View>

          <View>
            <View style={styles.tableHeader}><Text style={styles.headerCell}>{operatorName} - Хүлээн авсан гүйлгээнүүд</Text></View>
          </View>

          {incomingRow.map(( record, index) => (
            record.subDate ? (
              // End zasvar oroh yostoi
              <View key={`header-${index}`} style={styles.cellRow}>
                <View style={styles.headerCell}><Text>{record.subDate + " -ны нийт гүйлгээ"}</Text></View>
                <View style={styles.subHeadCell}><Text>{record.dateTotal}</Text></View>
              </View>
            ) : (
              <View key={`row-${index}`} style={styles.cellRow}>
                <View style={styles.cell}><Text></Text></View>
                <View style={styles.cell}><Text>{record.transactionCode}</Text></View>
                <View style={styles.cell}><Text>{record.date}</Text></View>
                <View style={styles.cell}><Text>{record.transactionBankCode}</Text></View>
                <View style={styles.cell}><Text>{record.transactionBankAccountNumber}</Text></View>
                <View style={styles.cell}><Text>{record.transactionAmount}</Text></View>
              </View>
            )
          ))}

          <View style={styles.cellRow}>
            <View style={styles.headerCell}><Text>{operatorName + " Нийт хүлээн авсан дүн"}</Text></View>
            <View style={styles.subHeadCell}><Text>{data.incoming.total}</Text></View>
          </View>

          <View>
            <View style={styles.tableHeader}><Text style={styles.headerCell}>{operatorName} - Илгээсэн гүйлгээнүүд</Text></View>
          </View>

          {outgoingRow.map(( record, index) => (
            record.subDate ? (
              // End zasvar oroh yostoi
              <View key={`header-${index}`} style={[styles.cellRow, styles.tableHeader]}>
                <View><Text>{record.subDate + " -ны нийт гүйлгээ"}</Text></View>
                <View style={styles.subHeadCell}><Text>{record.dateTotal}</Text></View>
              </View>
            ) : (
              <View key={`row-${index}`} style={styles.cellRow}>
                <View style={styles.cell}><Text></Text></View>
                <View style={styles.cell}><Text>{record.transactionCode}</Text></View>
                <View style={styles.cell}><Text>{record.date}</Text></View>
                <View style={styles.cell}><Text>{record.transactionBankCode}</Text></View>
                <View style={styles.cell}><Text>{record.transactionBankAccountNumber}</Text></View>
                <View style={styles.cell}><Text>{record.transactionAmount}</Text></View>
              </View>
            )
          ))}

          <View style={styles.cellRow}>
            <View style={styles.headerCell}><Text>{operatorName + " Нийт илгээсэн дүн"}</Text></View>
            <View style={styles.subHeadCell}><Text>{data.outgoing.total}</Text></View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default PDFTable;
