import React from "react";
import { Tag, Button, DatePicker, Input, Tooltip, Descriptions, Select, Space } from "antd";
import { message as messageApi } from "../../apis";
import { PageContainer, PageHeader, PageContent } from "../../components/Layout";
import { StickyHeaderCustomTable } from "../../components";
import { Filter } from "../../components/Design";
import { CopyOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import MessageTable from "../../components/Tables/messageTable";

const STATUS = [
  { value: "NEW" },
  { value: "PENDING" },
  { value: "SUCCESS" },
  { value: "FAILED" },
  { value: "REFUNDED" }
];

const TYPE = [
  { value: "WALLET" },
  { value: "MERCHANT" }
];
export default () => {
  const { RangePicker } = DatePicker;
  const myTableRef = React.useRef();
  const [options, setOptions] = React.useState([]);
  const [data, setData] = React.useState();

  const [filters, setFilters] = React.useState({
    startDate    : "",
    endDate      : "",
    operator_code : null,
    message_status: null,
    client_type   : null
  });

  const dateFilter = (e)=> {
    if (e) {
			setFilters({
				...filters,
				startDate: e && dayjs(e[0]).startOf("day").format("YYYY-MM-DDTHH:mm"),
				endDate: e && dayjs(e[1]).endOf("day").format("YYYY-MM-DDTHH:mm"),
			});
		} else {
			setFilters({
				...filters,
				startDate: null,
				endDate: null,
			});
		}
  };

  const dateFilter3 = (e)=> {
    setFilters({ ...filters, operator_code: e });
  };

  const dateFilter2 = (e)=> {
    setFilters({ ...filters, message_status: e });
  };

  const dateFilter4 = (e)=> {
    setFilters({ ...filters, client_type: e });
  };

  const onClear = () => {
    setFilters({
      startDate    : "",
      endDate      : "",
      operator_code : null,
      message_status: null,
      client_type   : null
    });
  };

  React.useEffect(() => {
    messageApi.list({})
      .then((e) => {
        setData(e);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, []);

  return (
    <PageContainer>
      <PageHeader title="Хүсэлтийн жагсаалт" />
      <PageContent>
        <Filter extra={[
          <Button key="clearBtnMessage" onClick={onClear}>
            Цэвэрлэх
          </Button>
        ]} children={[
          <RangePicker
            key="rangePickerMessage"
            value={
              filters.startDate && filters.endDate
                ? [dayjs(filters.startDate), dayjs(filters.endDate)]
                : null
            }
            onChange={dateFilter}
          />,
          <Select
            key="select1"
            // mode="multiple"
            style={{ width: "200px" }}
            options={STATUS}
            placeholder="Төлөв"
            maxTagCount="responsive"
            value={filters.message_status}
            onChange={(val) => {
              dateFilter2(val);
            }}
            optionRender={(option) => {
              return (
                <Space>
                  <span role="img" aria-label={option.label || option.value}>
                    {option.label || option.value}
                  </span>
                </Space>
              );
            }}
          />,
          <Select
            key="select2"
            style={{ width: "200px" }}
            options={options}
            placeholder="Оператор"
            maxTagCount="responsive"
            value={filters.operator_code}
            onChange={(val) => {
              dateFilter3(val);
            }}
            optionRender={(option) => {
              return (
                <Space>
                  <span role="img" aria-label={option.regUserId}>
                    {option.regUserId}
                  </span>
                </Space>
              );
            }}
          />,
          <Select
            key="select3"
            style={{ width: "200px" }}
            options={TYPE}
            placeholder="Төрөл"
            maxTagCount="responsive"
            value={filters.client_type}
            onChange={(val) => {
              dateFilter4(val);
            }}
            optionRender={(option) => {
              return (
                <Space>
                  <span role="img" aria-label={option.label || option.value}>
                    {option.label || option.value}
                  </span>
                </Space>
              );
            }}
          />,
        ]}>
        </Filter>
        <MessageTable
          data={data}
        />
        {/*<Table
          ref={myTableRef}
          filters={filters}
          loadData={messageApi.list}
          rowKey={(record) => record._id}
          thead={() => (
            <thead className="ant-table-thead sticky">
              <tr>
                <th className="ant-table-cell">№</th>
                <th className="ant-table-cell">Харилцагч</th>
                <th className="ant-table-cell">Төрөл</th>
                <th className="ant-table-cell">QR код</th>
                <th className="ant-table-cell">Хэрэглэгч</th>
                <th className="ant-table-cell">Төлөв</th>
                <th className="ant-table-cell">Огноо</th>
              </tr>
            </thead>
          )}
          tbody={(row, index) => {
            const pIndex = index + 1;
            const pRow = row;

            return (
              <>
                <tbody key={index} className="ant-table-tbody">
                  <tr>
                    <td className="ant-table-cell">{index + 1}</td>
                    <td className="ant-table-cell">
                      <div>
                        <div style={{ marginBottom: 5 }}>{row.operator_name}</div>
                        <Tag>{row.operator_code}</Tag>
                        <Tag color="black">{row.client_type}</Tag>
                      </div>
                    </td>
                    <td className="ant-table-cell">
                      <Tag>{row.msg_type}:{row.version_no}</Tag>
                    </td>
                    <td className="ant-table-cell">
                      <Input.Group compact>
                        <Input style={{ width: "calc(100% - 32px)" }} defaultValue={row.qrc_payload} />
                        <Tooltip title="Copy"><Button icon={<CopyOutlined />} /></Tooltip>
                      </Input.Group>
                    </td>
                    <td className="ant-table-cell">
                      <div>
                        <div style={{ marginBottom: 5 }}>{row.customer_name}</div>
                        <Tag>{row.customer_code}</Tag>
                      </div>
                    </td>
                    <td className="ant-table-cell">
                      <Tag>{row.message_status}</Tag>
                    </td>
                    <td className="ant-table-cell">{dayjs(row.message_status_date).format("YYYY-MM-DD HH:mm:ss")}</td>
                  </tr>
                </tbody>

                {row.children.map(() => {
                  return (
                    <tbody key={index} className="ant-table-tbody" style={{ background: "#fafafa" }}>
                      <tr>
                        <td className="ant-table-cell">{pIndex}.{index + 1}</td>
                        <td className="ant-table-cell">
                          <div>
                            <div style={{ marginBottom: 5 }}>{row.operator_name}</div>
                            <Tag>{row.operator_code}</Tag>
                            <Tag color="black">{row.client_type}</Tag>
                          </div>
                        </td>
                        <td className="ant-table-cell">
                          <Tag>{row.msg_type}:{row.version_no}</Tag>
                        </td>
                        <td className="ant-table-cell" style={{ padding: 0 }} colSpan={2}>
                          {(() => {
                            switch (row.msg_type) {
                              case "QR_INQUIRY_INFO": {
                                return (
                                  <Description bordered>
                                    <Description.Item label="Төлбөрийн дүн" span={4}>{row.amount} {row.currency}</Description.Item>
                                    <Description.Item label="Гүйлгээний утга" span={4}>{row.description}</Description.Item>
                                    <Description.Item label="Хүлээн авах данс" span={4}>
                                      <Description bordered>
                                        {row.bank_accounts.map(() => {
                                          return (
                                            <Description.Item key={index} label={row.bank_code} span={4}>
                                              <div>{row.account_name}</div>
                                              <Tag>{row.account_number}</Tag>
                                              {row.is_default && <Tag color="blue">Үндсэн</Tag>}
                                            </Description.Item>
                                          );
                                        })}
                                      </Description>
                                    </Description.Item>
                                  </Description>
                                );
                              }
                              case "QR_PAY": {
                                return (
                                  <Description bordered>
                                    {pRow?.client_type === "MERCHANT" && (
                                      <>
                                        <Description.Item label="Төлбөрийн дүн" span={4}>{pRow.amount} {pRow.currency}</Description.Item>
                                        <Description.Item label="Гүйлгээний утга" span={4}>{pRow.description}</Description.Item>
                                        <Description.Item label="Хүлээн авах данс" span={4}>
                                          <Description bordered>
                                            {pRow.bank_accounts?.map(() => {
                                              return (
                                                <Description.Item key={index} label={row.bank_code} span={4}>
                                                  <div>{row.account_name}</div>
                                                  <Tag>{row.account_number}</Tag>
                                                  {row.is_default && <Tag color="blue">Үндсэн</Tag>}
                                                </Description.Item>
                                              );
                                            })}
                                          </Description>
                                        </Description.Item>
                                      </>
                                    )}
                                    <Description.Item label="Нэхэмжлэл дугаар" span={2}>{row.invoice_code}</Description.Item>
                                    <Description.Item label="Төлбөрийн дугаар" span={2}>{row.payment_code}</Description.Item>
                                    <Description.Item label="Төлбөрийн төрөл" span={4}>{row.payment_method}</Description.Item>
                                  </Description>
                                );
                              }
                              case "QR_PAID": {
                                if (row.client_type === "MERCHANT")
                                  return (
                                    <Description bordered>
                                      <Description.Item label="Төлбөр төлсөн данс" span={4}>
                                        <Description bordered>
                                          <Description.Item key={index} label="VISA" span={4}>
                                            <div>{pRow.cardholder_name}</div>
                                            <Tag>{pRow.pan}</Tag>
                                            <Tag color="black">{pRow.expiry}</Tag>
                                          </Description.Item>
                                        </Description>
                                      </Description.Item>
                                    </Description>
                                  );

                                return (
                                  <Description bordered>
                                    {pRow?.client_type === "MERCHANT" && (
                                      <>
                                        <Description.Item label="Төлбөрийн дүн" span={4}>{pRow.amount} {pRow.currency}</Description.Item>
                                        <Description.Item label="Гүйлгээний утга" span={4}>{pRow.description}</Description.Item>
                                        <Description.Item label="Хүлээн авах данс" span={4}>
                                          <Description bordered>
                                            {pRow.bank_accounts?.map(() => {
                                              return (
                                                <Description.Item key={index} label={row.bank_code} span={4}>
                                                  <div>{row.account_name}</div>
                                                  <Tag>{row.account_number}</Tag>
                                                  {row.is_default && <Tag color="blue">Үндсэн</Tag>}
                                                </Description.Item>
                                              );
                                            })}
                                          </Description>
                                        </Description.Item>
                                      </>
                                    )}
                                    <Description.Item label="Төлбөр төлсөн данс" span={4}>
                                      <Description bordered>
                                        <Description.Item key={index} label={row.bank_code} span={4}>
                                          <div>{row.bank_account_name}</div>
                                          <Tag>{row.bank_account_number}</Tag>
                                        </Description.Item>
                                      </Description>
                                    </Description.Item>
                                  </Description>
                                );
                              }
                              default: return null;
                            }
                          })()}
                        </td>
                        <td className="ant-table-cell">
                          <Tag>{row.message_status}</Tag>
                        </td>
                        <td className="ant-table-cell">{dayjs(row.message_status_date).format("YYYY-MM-DD HH:mm:ss")}</td>
                      </tr>
                    </tbody>
                  );
                })}
              </>
            );
          }} />*/}
      </PageContent>
    </PageContainer>
  );
};

//const Table = styled(StickyHeaderCustomTable)`
//  .ant-table-cell {
//    padding: 8px;
//    line-height: 15px;
//  }
//  .sticky-header {
//    position: sticky !important;
//    top: 0;
//    background: "#fafafa";
//    z-index: 1;
//  }
//  .display-none {
//    display: none
//  }
//`;
//const Description = styled(Descriptions)`
//  .ant-descriptions-view {
//    border: 0;
//  }
//  .ant-descriptions-item-label, .ant-descriptions-item-content {
//    padding: 8px!important;
//  }
//  .ant-descriptions-item-label {
//    width: 200px;
//  }
//  .sticky-header {
//    position: sticky !important;
//    top: 0;
//    background: "#fafafa";
//    z-index: 1;
//  }

//  .display-none {
//    display: none
//  }

//  .position-absolute {
//    position: sticky,
//  }
//  .sticky-row-1 {
//    position: sticky;
//    top: 0;
//    background: #fff;
//    z-index: 2;
//  }
//`;