import React from "react";
import {Routes, Route} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {general, auth} from "./apis";
import {PrivateRoute, PublicRoute, NavBar, Header} from "./components";
import {Login, Notfound} from "./pages";
import {UnsuccessfulTransaction, SuccessfulTransaction, NetSettlement, ReconcilationReport, MonthlyBill, MonthlySuccessfulTransaction, MonthlyUnSuccessfulTransaction} from "./pages/Report";
import Connection from "./pages/Connection";
import Message from "./pages/Message";
import Dashboard from "./pages/Dashboard";
import Transaction from "./pages/Transaction";
import Refund from "./pages/Refund";
import styled from "styled-components";

const App=({isMobile}) => {
  const dispatch=useDispatch();
  const {accessToken}=useSelector((state) => state.auth);
  const [loading,setLoading]=React.useState(true);

  React.useEffect(() => {
    const asyncFn=async () => {
      try {
        await Promise.all([general.init()(dispatch)]);
        setLoading(false);
      } catch(e) {
        setLoading(false);
      }
    };

    asyncFn();

    if(accessToken)
      auth.me()(dispatch);

  },[dispatch,accessToken]);

  React.useEffect(() => {
    dispatch({
      type: "layout.mobile",
      payload: isMobile
    });
  },[isMobile]);

  if(loading) return <div>loading...</div>;
  
  return ( !accessToken ? <PublicRoute path="/login" element={<Login/>}/> :
    <Container isMobile={isMobile}>
      {/*<PublicRoute path="/login" element={<Login/>}/>*/}
      <Header isMobile={isMobile} />
      <div className="content">
        <div className="navbar">
          <NavBar isMobile={isMobile} />
        </div>
        <div className="wrapper">
          <Routes>
            <Route path="/" element={<PrivateRoute />}>
              <Route path="/" element={<Dashboard />} />
              <Route path="/connection" element={<Connection />} />
              <Route path="/message" element={<Message />} />
              <Route path="/transaction/:type" element={<Transaction />} />
              <Route path="/refund/:type" element={<Refund />} />
              <Route path="/report/unsuccessful_trans" element={<UnsuccessfulTransaction />} />
              <Route path="/report/successful_trans" element={<SuccessfulTransaction />} />
              <Route path="/report/net_settlement" element={<NetSettlement />} />
              <Route path="/report/reconcilation" element={<ReconcilationReport />} />
              <Route path="/report/monthly_bill" element={<MonthlyBill />} />
              <Route path="/report/monthly/successful_trans" element={<MonthlySuccessfulTransaction />} />
              <Route path="/report/monthly/unsuccessful_trans" element={<MonthlyUnSuccessfulTransaction />} />
            </Route>
            <Route path="*" element={<Notfound />} />
          </Routes>
        </div>
      </div>
    </Container>
  );
};

export default App;

const Container=styled.div`
  overflow: hidden; 
  .content { 
    position: relative;
    width: 100%; 
    background: #fff;
    .navbar {
      display: flex;
      width: ${(props) => props.isMobile? 80:230}px;
      border-right: 1px solid #e8e8e8;
      height: calc(100vh - 60px);
      flex-direction: column;
      position: absolute;
      left: 0;
      top: 0;
      overflow: auto;
      overflow-x: hidden; 
      background: #ffffff;
      ::-webkit-scrollbar {
        width: 2px;
        height: 2px;
      }
      ::-webkit-scrollbar-button {
        width: 2px;
        height: 2px;
      }
      ::-webkit-scrollbar-thumb {
        background: #1890ff;
        border: 0px none #ffffff;
        border-radius: 50px;
      } 
    }
    .wrapper {
      position: relative; 
      padding-left: ${(props) => props.isMobile? 80:230}px;
      height: calc(100vh - 60px);
      overflow: auto;
      overflow-x: hidden; 
    }
  }
`;
