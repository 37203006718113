import React from 'react';
import { Document, Page, Text, View, StyleSheet, Font } from '@react-pdf/renderer';
import source from "../../assets/fonts/Roboto-Regular.ttf";
import dayjs from 'dayjs';

Font.register({ family: 'Roboto', src: source });

const styles=StyleSheet.create({
  table: {
    display: "table",
    width: "100%",
    padding: 20
  },
  tableRow: {
    flexDirection: "row"
  },
  tableCol: {
    width: "33%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: '#bfbfbf',
    padding: 5,
    fontFamily: "Roboto",
    fontSize: 15
  },
  tableHeader: {
    backgroundColor: '#eeeeee',
    fontWeight: "bold"
  },
  tableCell: {
    fontSize: 10
  },
});

const PDFTable=({ data, filters, paricipment }) => {
  const rows = data? [
    {
      paricipment: paricipment,
      debitAmount: data.debitAmount,
      creditAmount: data.creditAmount,
    },
    {
      paricipment: "Нийт дүн",
      debitAmount: data.debitAmount,
      creditAmount: data.creditAmount,
    }
  ]:[];

  const formatedDate = filters ? 
    {
      startDate : dayjs(filters.startDate).format('YYYY-MM-DD'),
      endDate : dayjs(filters.endDate).format('YYYY-MM-DD')
    }
  : [];

  return (
    <Document>
      <Page size="A4">
        <View style={styles.table}>
          <View style={[styles.tableRow,styles.tableHeader]}>
            <View style={styles.tableCol}><Text>Хаалтын хамрах үе</Text></View>
            <View style={styles.tableCol}><Text>Эхэлсэн цаг {formatedDate.startDate}</Text></View>
            <View style={styles.tableCol}><Text>Дууссан цаг {formatedDate.endDate}</Text></View>
          </View>
          <View style={[styles.tableRow,styles.tableHeader]}>
            <View style={styles.tableCol}><Text>Хаалтын оролцогч</Text></View>
            <View style={styles.tableCol}><Text>Кредит дүн</Text></View>
            <View style={styles.tableCol}><Text>Дебит дүн</Text></View>
          </View>

          {rows.map((row,index) => (
            <View style={styles.tableRow} key={index}>
              <View style={styles.tableCol}><Text style={styles.tableCell}>{row.paricipment}</Text></View>
              <View style={styles.tableCol}><Text style={styles.tableCell}>{row.debitAmount}</Text></View>
              <View style={styles.tableCol}><Text style={styles.tableCell}>{row.creditAmount}</Text></View>
            </View>
          ))}
        </View>
      </Page>
    </Document>
  );
};

export default PDFTable;
