import React from "react";
import { Table } from "antd";
import moment from "moment";
import TablePagination from "@mui/material/TablePagination";
import Box from "@mui/material/Box";
import { Pagination } from "@mui/material";

const TransactionTable = (
  {
    paricipment,
    loadData,
    columns,
    data,
    pagination = true,
    rowExpandable,
    filters,
    items: rows,
    setHeaderIndexes,
    setSubheaderIndexes,
    perPage,
    getData,
    value,
    headerIndexes,
    subheaderIndexes,
    el,
    isSuccess,
    onResult = () => {},
    ...rest
  },
  ref
) => {
  const [loading, setLoading] = React.useState(true);
  const [total, setTotal] = React.useState(0);
  
  // const [tableFilter, setTableFilter] = React.useState({});

  const field = null;
  const sort = null;
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(perPage);
  const pageCount = 1;
  const totalEl = 1;
  // const [pageCount, setPageCount] = React.useState(1);
  // const [totalEl, setTotalEl] = React.useState(1);
  const [items, setItems] = React.useState([]);

  const initialRenderPage = React.useRef(true);
  const initialRenderLimit = React.useRef(true);

  const [tableheaderIdx, setTableheaderIdx] = React.useState([]);
  const [tablesubheaderIdx, setTablesubheaderIdx] =
    React.useState([]);

  const reload = React.useCallback(
    async (signal) => {
      if (!loadData) return;
      setLoading(true);

      const res = await loadData(
        {
          filter: {
            query: "",
            ...filters,
          },
          order: {
            [field]: sort,
          },
          offset: {
            page : page,
            limit: limit,
          },
        },
        { signal }
      );

      setItems(
        res.rows.map((row, index) => ({
          ...row,
          rowKey: index + 1,
        }))
      );
      setTotal(res.count);

      if (onResult) onResult(res);

      setLoading(false);
    },
    [filters, limit, loadData, field, sort, page]
  );

  React.useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    reload(signal);

    return () => abortController.abort();
  }, [reload]);

  React.useImperativeHandle(ref, () => ({
    reload() {
      reload();
    },
    setItems() {
      setItems(items);
    },
    getLoading() {
      return loading;
    },
    setLoading(loadStatus) {
      setLoading(loadStatus);
    },
    getPage() {
      return page;
    },
    getLimit() {
      return limit;
    },
    setPage(newPage) {
      setPage(newPage);
    },
    setLimit(val) {
      setLimit(val);
    },
  }));

  React.useEffect(() => {
    if (rows) {
      setItems(rows);
      setTotal(rows.length);
    }
  }, [rows]);

  const useHeader = () => {
    const sharedOnCell = (_, index) => {
      if (tableheaderIdx.includes(index) || tablesubheaderIdx.includes(index)) {
        return {
          colSpan: 0,
        };
      }
      return {
        colSpan: 1,
      };
    };

    const fixNest = (_, index) => {
      if (tableheaderIdx.includes(index)){
        return 6;
      }
      if (tablesubheaderIdx.includes(index)){
        return 5;
      }
      return 1;
    };

    const fixNest2 = (_, index) => {
      if (tableheaderIdx.includes(index)){
        return "header-cell";
      }
      if (tablesubheaderIdx.includes(index)){
        return "subheader-cell";
      }
      return "cell-center";
    };

    const onHeaderCell = (_, index) => {
      let idx = fixNest(_, index);
      let classname = fixNest2(_, index);
      return {
        colSpan  : idx,
        className: classname
      };
    };

    return [
      {
        title : "Харилцагч",
        align : "left",
        render: (record) => {
          return (
            <div
              style={{
                padding: "8px",
              }}
            >
              {record.key}
            </div>
          );
        },
        onCell: onHeaderCell,
      },
      {
        title : "Гүйлгээний дугаар",
        render: (record) => {
          return <div style={{ padding: "8px" }}>{record.transactioncode}</div>;
        },
        onCell: sharedOnCell,
      },
      {
        title : "Огноо",
        render: (record) => {
          return (
            <div style={{ padding: "8px" }}>
              {record.date ? moment(record.date).format("YYYY-MM-DD") : "-"}
            </div>
          );
        },
        onCell: (_, index) => ({
          colSpan:
            tableheaderIdx.includes(index) || tablesubheaderIdx.includes(index)
              ? 0
              : 1,
        }),
      },
      {
        title : "Илгээсэн харилцагч",
        render: (record) => {
          return <div style={{ padding: "8px" }}>{record.transaction_bank_code}</div>;
        },
        onCell: (_, index) => ({
          colSpan:
            tableheaderIdx.includes(index) || tablesubheaderIdx.includes(index)
              ? 0
              : 1,
        }),
      },
      {
        title : "Илгээсэн данс дугаар",
        render: (record) => {
          return (
            <div style={{ padding: "8px" }}>{record.transaction_bank_account_number}</div>
          );
        },
        onCell: (_, index) => ({
          colSpan:
            tableheaderIdx.includes(index) || tablesubheaderIdx.includes(index)
              ? 0
              : 1,
        }),
      },
      {
        title : "Гүйлгээний дүн",
        render: (record) => {
          return (
            <div style={{ padding: "8px" }}>{record.transactionAmount}</div>
          );
        },
        onCell: (_, index) => ({
          colSpan  : tableheaderIdx.includes(index) ? 0 : 1,
          className: tablesubheaderIdx.includes(index) ? "subvalue-cell" : "",
        }),
      },
    ];
  };

  React.useEffect(() => {
    let headerIdx = [];
    let subheaderIdx = [];
    const listData = [];
    if (data != null) {
      if (data && data.incoming && Array.isArray(data.incoming.data)) {
        listData.push({
          key: `${paricipment} - Хүлээн авсан ${!isSuccess ? "амжилтгүй" : ""} гүйлгээнүүд`,
        });
        headerIdx.push(0);
        data.incoming.data.forEach((item) => {
          const date = Object.keys(item)[0];
          if (date) {
            item[date].forEach((e) => {
              listData.push({
                key: "",
                ...e,
              });
            });
            listData.push({
              key              : `${moment(date).format("YYYY-MM-DD")} -ны нийт ${isSuccess ? "гүйлгээ" : "амжилтгүй дүн"}`,
              transactionAmount: item.total[0].total_amount,
            });
            subheaderIdx.push(listData.length - 1);
          }
        });
        listData.push({
          key              : `${paricipment} ${isSuccess ? "Нийт хүлээн авсан дүн" : "Хүлээн авсан амжилтгүй дүн"}`,
          transactionAmount: data.incoming.total,
        });
        subheaderIdx.push(listData.length - 1);
      }

      if (data && data.outgoing && Array.isArray(data.outgoing.data)) {
        listData.push({
          key: `${paricipment} - Илгээсэн ${!isSuccess ? "амжилтгүй" : ""} гүйлгээнүүд`,
        });
        headerIdx.push(listData.length - 1);
        data.outgoing.data.forEach((item) => {
          const date = Object.keys(item)[0];
          if (date) {
            item[date].forEach((e) => {
              listData.push({
                // key: i + 1,
                key: "",
                ...e,
              });
            });
            listData.push({
              key              : `${moment(date).format("YYYY-MM-DD")} -ны нийт ${isSuccess ? "гүйлгээ" : "амжилтгүй дүн"}`,
              transactionAmount: item.total[0].total_amount,
            });
            subheaderIdx.push(listData.length - 1);
          }
        });
        listData.push({
          key              : `${paricipment} ${isSuccess ? "Нийт илгээсэн дүн" : "Илгээсэн амжилтгүй дүн"}`,
          transactionAmount: data.outgoing.total,
        });
        subheaderIdx.push(listData.length - 1);
      }

      setTableheaderIdx(headerIdx);
      setTablesubheaderIdx(subheaderIdx);
      setLoading(false);
      setItems(listData);

      //console.log("111111", listData);

      // if (data.pagination) {
      //  if (
      //    data.pagination.paginationIncoming.total_elements >
      //    data.pagination.paginationOutgoing.total_elements
      //  ) {
      //    setTotal(data.pagination.paginationIncoming.total_elements);
      //  } else {
      //    setTotal(data.pagination.paginationOutgoing.total_elements);
      //  }

      //  // if (
      //  //   data.pagination.paginationIncoming.per_page >
      //  //   data.pagination.paginationOutgoing.per_page
      //  // ) {
      //  //   setLimit(data.pagination.paginationIncoming.per_page);
      //  // } else {
      //  //   setLimit(data.pagination.paginationOutgoing.per_page);
      //  // }

      //  // if (
      //  //   data.pagination.paginationIncoming.current_page_no >
      //  //   data.pagination.paginationOutgoing.current_page_no
      //  // ) {
      //  //   setPage(data.pagination.paginationIncoming.current_page_no);
      //  // } else {
      //  //   setPage(data.pagination.paginationOutgoing.current_page_no);
      //  // }

      //  if (
      //    data.pagination.paginationIncoming.total_pages >
      //    data.pagination.paginationOutgoing.total_pages
      //  ) {
      //    setPageCount(data.pagination.paginationIncoming.total_pages);
      //  } else {
      //    setPageCount(data.pagination.paginationOutgoing.total_pages);
      //  }

      //  setTotalEl(
      //    data.pagination.paginationOutgoing.total_elements +
      //      data.pagination.paginationIncoming.total_elements
      //  );
      // } else {
      //  console.log("");
      // }
    }

    //console.log("22222", items);
  }, [data]);

  // React.useEffect(() => {
  //   // value.forEach((el) => {
  //   //   getData(el);
  //   // });
  //   getData(el);
  // }, [page]);

  // React.useEffect(() => {
  //   getData(el);
  // }, [limit]);

  React.useEffect(() => {
    if (initialRenderPage.current) {
      initialRenderPage.current = false;
    } else {
      getData();
      //console.log(total);
    }
  }, [page]);

  React.useEffect(() => {
    if (initialRenderLimit.current) {
      initialRenderLimit.current = false;
    } else {
      getData();
    }
  }, [limit]);

  return (
    <>
      <Table
        {...{
          bordered  : true,
          columns   : useHeader({}),
          dataSource: items,
          rowExpandable,
          pagination: false,
          ...rest,
        }}
        loading={loading}
        rowKey={(record, index) => record.id || index}
      />
      {pagination &&(
        <Box
          className="custom-pagination"
          style={{
            marginTop     : "0px",
            display       : "flex",
            flexDirection : "row",
            alignItems    : "center",
            justifyContent: "flex-end",
          }}
        >
          <TablePagination
            component="div"
            count={totalEl}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={[5, 10, 20, 50, 100]}
            onRowsPerPageChange={(event) => {
              setLimit(parseInt(event.target.value, 10));
              setPage(1);
            }}
            sx={{
              "& p": {
                marginTop   : "auto",
                marginBottom: "auto",
              },
              ".MuiTablePagination-actions > button:first-of-type": {
                display: "none",
              },
              ".MuiTablePagination-actions > button:last-of-type": {
                display: "none",
              },
              ".MuiTablePagination-displayedRows": {
                display: "none",
              },
            }}
          />
          <Pagination
            count={pageCount}
            page={page}
            onChange={(_, newPage) => {
              setPage(newPage);
            }}
            color="primary"
            shape="rounded"
          />
        </Box>
      )}
    </>
  );
};

export default React.forwardRef(TransactionTable);
