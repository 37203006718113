import qs from "qs";
import { message, notification } from "antd";
import { store } from "../";

const statusHandler = async (response) => {
  if (response.status >= 200 && response.status < 400) {
    return response;
  }

  const error = new Error(response.statusText);
  error.status = response.status;
  error.response = response;

  throw error;
};

const nonJsonErrorHandler = {
  type   : "Error",
  payload: "Сервертэй холбогдоход алдаа гарлаа",
};

const errorHandler = async (error) => {
  if (error.response && error.response.status === 401) {
    store.dispatch({
      type: "auth.logout",
    });

    return {
      type   : "Error",
      payload: "Хэрэглэгчээр нэвтрэх шаардлагатай",
    };
  }

  if (error.response) {
    try {
      const json = await error.response.json();

      if (json.message && typeof json.message === "string") {
        notification.warning({
          message    : "Санамж",
          description: json.message
        });
      }

      throw json;
    } catch (jsonParseError) {
      throw nonJsonErrorHandler;
    }
  } else {
    throw nonJsonErrorHandler;
  }
};

// const api_host = process.env.NODE_ENV === "production" ? process.env.API_HOST || "" : "";

const request = async (url, data, options) => {
  let defaultOptions = {
    credentials: "include",
    headers    : {
      Accept        : "application/json",
      "Content-Type": "application/json; charset=utf-8",
      'X-Api-Key': '28cc126e-203b-44ae-903d-5290a5babd00',
    },
    body: JSON.stringify(data),
    ...options,
  };

  let queryString = "";

  if (options.method === "GET") {
    delete defaultOptions.body;

    queryString = `${qs.stringify(data)}`;
  }

  const { accessToken } = store.getState().auth;

  if (accessToken) {
    defaultOptions.headers.Authorization = "Bearer " + accessToken;
  }

  try {
    const res = await fetch(`${url}?${queryString}`, defaultOptions);

    await statusHandler(res);

    const json = await res.json();

    return json;
  } catch (err) {
    await errorHandler(err);
    return null;
  }
};

const messageRequest = async (url, options) => {
  let defaultOptions = {
    //credentials: "include",
    headers    : {
      "Accept"        : "application/json",
      "Content-Type": "application/json; charset=utf-8",
      'X-API-KEY': '28cc126e-203b-44ae-903d-5290a5babd00',
    },
    //body: JSON.stringify(data),
    ...options,
  };
  const host = process.env.REACT_APP_SWITCH_URL;
  console.log("apiUrl", process.env.REACT_APP_SWITCH_URL);

  try {
    const res = await fetch(`${host}/${url}`, defaultOptions);

    await statusHandler(res);

    const json = await res.json();

    return json;
  } catch (err) {
    await errorHandler(err);
    return null;
  }
};

const httpMethod = (signal) => {
  return {
    messageGet: (url, data, options = {}) => {
      const updatedOptions = { ...options }; // Create a copy of options
      if (signal) {
        updatedOptions.signal = signal; // Modify the copy
      }
      return messageRequest(url, data, { ...updatedOptions, method: "GET" });
    },
    get: (url, data, options = {}) => {
      const updatedOptions = { ...options }; // Create a copy of options
      if (signal) {
        updatedOptions.signal = signal; // Modify the copy
      }
      return request(url, data, { ...updatedOptions, method: "GET" });
    },
    post: (url, data, options = {}) => {
      const updatedOptions = { ...options }; // Create a copy of options
      if (signal) {
        updatedOptions.signal = signal; // Modify the copy
      }
      return request(url, data, { ...updatedOptions, method: "POST" });
    },
    put: (url, data, options = {}) => {
      const updatedOptions = { ...options }; // Create a copy of options
      if (signal) {
        updatedOptions.signal = signal; // Modify the copy
      }
      return request(url, data, { ...updatedOptions, method: "PUT" });
    },
    del: (url, data, options = {}) => {
      const updatedOptions = { ...options }; // Create a copy of options
      if (signal) {
        updatedOptions.signal = signal; // Modify the copy
      }
      return request(url, data, { ...updatedOptions, method: "DELETE" });
    }
  };
};


export default {
  ...httpMethod(),
  signal: (signal) => httpMethod(signal)
};