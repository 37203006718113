import React from "react";
import { Table } from "antd";
import moment from "moment";

const MonthlyBillingTable = (
  {
    paricipment,
    loadData,
    data,
    rowExpandable,
    filters,
    items: rows,
    setHeaderIndexes,
    setSubheaderIndexes,
    perPage,
    getData,
    value,
    el,
    headerIndexes = [],  // Default to an empty array
    subheaderIndexes = [], // Default to an empty array
    initialLoading,
    isSuccess,
    onResult = () => {},
    ...rest
  },
  ref
) => {
  const [loading, setLoading] = React.useState(initialLoading);
  const [items, setItems] = React.useState([]);

  const field = null;
  const sort = null;
  const page = 1;
  const limit = perPage;

  const initialRenderPage = React.useRef(true);
  const initialRenderLimit = React.useRef(true);

  const tableheaderIdx = headerIndexes;
  const tablesubheaderIdx = subheaderIndexes;

  const reload = React.useCallback(
    async (signal) => {
      if (!loadData) return;
      setLoading(true);

      const res = await loadData(
        {
          filter: {
            query: "",
            ...filters,
          },
          order: {
            [field]: sort,
          },
          offset: {
            page: page,
            limit: limit,
          },
        },
        { signal }
      );

      setItems(
        res.rows.map((row, index) => ({
          ...row,
          rowKey: index + 1,
        }))
      );

      if (onResult) onResult(res);

      setLoading(false);
    },
    [filters, limit, loadData, field, sort, page]
  );

  React.useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    reload(signal);

    return () => abortController.abort();
  }, [reload]);

  React.useImperativeHandle(ref, () => ({
    reload() {
      reload();
    },
    setItems() {
      setItems(items);
    },
    getLoading() {
      return loading;
    },
    setLoading(loadStatus) {
      setLoading(loadStatus);
    },
    getPage() {
      return page;
    },
    getLimit() {
      return limit;
    },
  }));

  React.useEffect(() => {
    if (rows) {
      setItems(rows);
    }
  }, [rows]);

  React.useEffect(() => {
    let headerIdx = [];
    let subheaderIdx = [];
    const listData = [];
    if (data != null) {
      if (data && data.incoming && Array.isArray(data.incoming.data)) {
        listData.push({
          header: `${paricipment} - Хүлээн авсан ${!isSuccess ? "амжилтгүй" : ""} гүйлгээнүүд`,
        });
        headerIdx.push(0);
        data.incoming.data.forEach((item) => {
          const date = Object.keys(item)[0];
          const formattedDate = moment(date).format("YYYY-MM-DD");
          if (date) {
            item[date].forEach((e) => {
              listData.push({
                ...e,
                date: formattedDate,
              });
            });
            let totalAmount = 0;
            data.incoming.totals.forEach((e) => {
              if (e.date === date) {
                totalAmount = e.totalAmount;
              }
            });
            listData.push({
              subTitle: `${moment(date).format("YYYY-MM-DD")} -ны нийт ${isSuccess ? "гүйлгээ" : "амжилтгүй дүн"}`,
              charge: totalAmount,
            });
            subheaderIdx.push(listData.length - 1);
          }
        });
        listData.push({
          subTitle: `${paricipment} ${isSuccess ? "Нийт хүлээн авсан дүн" : "Хүлээн авсан амжилтгүй дүн"}`,
          charge: data.incoming.totalAmount,
        });
        subheaderIdx.push(listData.length - 1);
      }

      if (data && data.outgoing && Array.isArray(data.outgoing.data)) {
        listData.push({
          header: `${paricipment} - Илгээсэн ${!isSuccess ? "амжилтгүй" : ""} гүйлгээнүүд`,
        });
        headerIdx.push(listData.length - 1);
        data.outgoing.data.forEach((item) => {
          const date = Object.keys(item)[0];
          const formattedDate = moment(date).format("YYYY-MM-DD");
          if (date) {
            item[date].forEach((e) => {
              listData.push({
                ...e,
                date: formattedDate,
              });
            });
            let totalAmount = 0;
            data.outgoing.totals?.forEach((e) => {
              if (e.date === date) {
                totalAmount = e.totalAmount;
              }
            });
            listData.push({
              subTitle: `${moment(date).format("YYYY-MM-DD")} -ны нийт ${isSuccess ? "гүйлгээ" : "амжилтгүй дүн"}`,
              charge: totalAmount,
            });
            subheaderIdx.push(listData.length - 1);
          }
        });
        listData.push({
          subTitle: `${paricipment} ${isSuccess ? "Нийт илгээсэн дүн" : "Илгээсэн амжилтгүй дүн"}`,
          charge: data.outgoing.totalAmount,
        });
        subheaderIdx.push(listData.length - 1);
      }
      setItems(listData);
    }
  }, [data]);

  const useHeader = () => {
    const sharedOnCell = (record) => {
      if (record.header) return { colSpan: 0 };
      if (record.subTitle) return { colSpan: 0 };
      return { colSpan : 1 };
    };

    const onHeaderCell = (_, index) => {
      let colSpan = 6
      let className = Array.isArray(tableheaderIdx) && tableheaderIdx.includes(index) ? "header-cell" :
                      Array.isArray(tablesubheaderIdx) && tablesubheaderIdx.includes(index) ? "subheader-cell" : "cell-center";

      return { colSpan, className };
    };

    const onSubHeaderCell = (_, index) => {
      let colSpan = 5
      let className = Array.isArray(tableheaderIdx) && tableheaderIdx.includes(index) ? "header-cell" :
                      Array.isArray(tablesubheaderIdx) && tablesubheaderIdx.includes(index) ? "subheader-cell" : "cell-center";

      return { colSpan, className };
    };

    return [
      {
        title: "Огноо",
        align: "left",
        render: (record) => {
          if(record.header) {
            return <div>{record.header}</div>
          } else if (record.subTitle) {
            return <div>{record.subTitle}</div>
          } else return <div style={{ padding: "8px" }}>{record.date}</div>
        },
        onCell: (record, index) => {
          if (record.header) {
            return onHeaderCell(record, index);
          } else if (record.subTitle) {
            return onSubHeaderCell(record, index);
          } else {
            return sharedOnCell(record, index)
          }
        },
      },
      {
        title: "Систем",
        render: (record) => {
          if (!(record.subTitle || record.header)) {
            return <div style={{ padding: "8px" }}>ePay</div>
          } return null;
        },
        onCell: sharedOnCell,
      },
      {
        title: "Гүйлгээний төрөл",
        render: (record) => {
          if (!(record.subTitle || record.header)) {
            return <div style={{ padding: "8px" }}>{record.type}</div>
          } return null;
        },
        onCell: sharedOnCell,
      },
      {
        title: "Гүйлгээний тоо",
        render: (record) => <div style={{ padding: "8px" }}>{record.quantity}</div>,
        onCell: sharedOnCell,
      },
      {
        title: "Нэхэмжлэх шимтгэл дүн",
        render: (record) => {
          return <div style={{ padding: "8px" }}>{record.charge}</div>
        },
        onCell: (record, index) => ({
          colSpan: record.header ? 0 : 1,
          className: Array.isArray(tablesubheaderIdx) && tablesubheaderIdx.includes(index) ? "subvalue-cell" : "",
        }),
      },
      {
        title: "Гүйлгээний нийт дүн",
        render: (record) => <div style={{ padding: "8px" }}>{record.amount}</div>,
        onCell: sharedOnCell,
      },
    ];
  };

  return (
    <Table
      bordered
      columns={useHeader()}
      dataSource={items}
      rowExpandable={rowExpandable}
      pagination={false}
      loading={initialLoading}
      rowKey={(record, index) => record.id || index}
      {...rest}
    />
  );
};

export default React.forwardRef(MonthlyBillingTable);
