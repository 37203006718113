import { Document, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import dayjs from 'dayjs';
import stringToFloat from "../../utils/parser";
import formatNumber from "../../utils/format";

const styles = StyleSheet.create({
  table: {
    margin: 20,
    display: "flex",
    border: "1px solid #000",
    flexDirection: "column",
  },
  tableHeader: {
    flexDirection: "row",
  },  
  tableCell: {
    flexDirection: "row",
    backgroundColor: "#f3f3f3",
    border: "1px solid #000",
    fontFamily: "Roboto",
    width: "100%",
    padding: 5,
    fontSize: 12,
    textAlign: "center"
  },
  cellRow: {
    flexDirection: "row",
    borderBottom: "1px solid #000",
  },
  cell: {
    flex: 1,
    padding: 5,
    fontSize: 10,
    textAlign: "center",
    borderLeft: "1px solid #000",
  }
});


const PdfTable = ( data ) => {
  const rows = [];
  const totalRow = [];

  if (data.data !== null) {
    let totalDebitCount = 0;
    let totalCreditCount = 0;
    let totalDebitAmount = 0;
    let totalCreditAmount = 0;

    data.data.forEach(el => {
      totalDebitCount += stringToFloat(el.debitCount.toString());
      totalCreditCount += stringToFloat(el.creditCount.toString());
      totalDebitAmount += stringToFloat(el.debitAmount.toString());
      totalCreditAmount += stringToFloat(el.creditAmount.toString());

      rows.push({
        date: dayjs(el.date).format('YYYY-MM-DD'),
        debitCount: el.debitCount,
        creditCount: el.creditCount,
        debitAmount: el.debitAmount,
        creditAmount: el.creditAmount,
      });
    });
    totalRow.push({
      total: "Нийт",
      totalDebitCount: totalDebitCount,
      totalCreditCount: totalCreditCount,
      totalDebitAmount : formatNumber(totalDebitAmount),
      totalCreditAmount: formatNumber(totalCreditAmount),
    });
  };

  const rowsRender = () => {
    return rows.map((record, index) => (
      <View key={`row-${index}`} style={styles.cellRow}>
        <View style={styles.cell}><Text>{record.date}</Text></View>
        <View style={styles.cell}><Text>{record.debitCount}</Text></View>
        <View style={styles.cell}><Text>{record.creditCount}</Text></View>
        <View style={styles.cell}><Text>{record.debitAmount}</Text></View>
        <View style={styles.cell}><Text>{record.creditAmount}</Text></View>
      </View>
    ));
  };

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.table}>
          <View style={styles.tableHeader}>
            <View style={styles.tableCell}><Text>Огноо</Text></View>
            <View style={styles.tableCell}><Text>Дебит гүйлгээний тоо</Text></View>
            <View style={styles.tableCell}><Text>Кредит гүйлгээний тоо</Text></View>
            <View style={styles.tableCell}><Text>Дебит гүйлгээний дүн</Text></View>
            <View style={styles.tableCell}><Text>Кредит гүйлгээний дүн</Text></View>
          </View>

          {rowsRender()}

          <View style={styles.tableHeader}>
            <View style={styles.tableCell}><Text>{totalRow[0].total}</Text></View>
            <View style={styles.tableCell}><Text>{totalRow[0].totalDebitCount}</Text></View>
            <View style={styles.tableCell}><Text>{totalRow[0].totalCreditCount}</Text></View>
            <View style={styles.tableCell}><Text>{totalRow[0].totalDebitAmount}</Text></View>
            <View style={styles.tableCell}><Text>{totalRow[0].totalCreditAmount}</Text></View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default PdfTable;