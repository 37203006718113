import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

const PrivateRoute = () => {
  const { accessToken } = useSelector((state) => state.auth);

  // If the user is not authenticated, redirect to the login page
  if (!accessToken) {
    return <Navigate to="/" replace />;
  }

  // Otherwise, render the children components (nested routes)
  return <Outlet />;
};

export default PrivateRoute;
 