import React, { useEffect } from "react";
import { Tag } from "antd";
import { connection as connectionApi } from "../../apis";
import { PageContainer, PageHeader, PageContent } from "../../components/Layout";
import dayjs from "dayjs";
import { Table } from "antd";

export default () => {
  const myTableRef = React.useRef();
  const [data, setData] = React.useState(null);
  
  useEffect(() => {
    connectionApi.stats({})
      .then((e) => {
        setData(e);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, []);

  const useHeader = () => {
    return [{
      title : "Харилцагч",
      render: (record) => {
        return (
          <div key={`${record.rowKey}-operator`}>
            <div style={{ marginBottom: 5 }}>{record.name}</div>
          </div>
        );
      }
    }, {
      title : "Client",
      render: (record) => {
        return (
          <div key={`${record.rowKey}-client`}>
            <div style={{ marginBottom: 5 }}>{record.clientType}</div>
            <Tag key={`${record.rowKey}-clientId`}>{record.clientId}</Tag>
          </div>
        );
      }
    }, {
      title : "Host",
      render: (record) => {
        return (<div key={`${record.rowKey}-host`}>{record.host}:{record.port}</div>);
      }
    }, {
      title : "Status",
      render: (record) => {
        return record.status ? 
        <Tag color="green">CONNECTED</Tag> : <Tag color="red">DISCONNECTED</Tag>;
      }
    }, {
      title : "Last request date",
      render: (record) => {
        return (<div>{record.lastRequestDate ? dayjs(record.lastRequestDate).format("YYYY-MM-DD HH:mm:ss") : "-"}</div>);
      }
    }];
  };

  return (
    <PageContainer>
      <PageHeader title="Систем холболт" />
      <PageContent>
        <Table
          ref={myTableRef}
          dataSource={data}
          columns={useHeader()}
          rowKey={(record) => record.code}
          bordered
        />
      </PageContent>
    </PageContainer>
  );
};