import React from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { Button, Form as AntForm, message } from "antd";
import { FormItem, Input, Form } from "formik-antd";
import { transaction as transactionApi } from "../../apis";

const FormSchema = () => Yup.object({
  note: Yup.string().required("Заавал бөглөнө!")
});

export default ({ action, onCancel }) => {
  const [data] = React.useState({
    note: undefined,
    id  : action[1]?.id
  });

  const onSubmit = async (values) => {
    try {
      await transactionApi.refund(data.id, {
        note: values.note
      });

      message.success("Таны хүсэлт амжилттай!");

      onCancel(true);
    } catch (err) {
      message.error(err?.message || "Таны хүсэлт амжилтгүй!");
    }
  };

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={data}
        validationSchema={FormSchema()}
        onSubmit={onSubmit}>
        {({ isSubmitting }) => {
          return (
            <Form layout="vertical">
              <FormItem name="note" label="Буцаалт хийх шалтгаан" required>
                <Input.TextArea name="note" placeholder="Буцаалт хийх шалтгаан" maxLength={500} showCount rows={3} />
              </FormItem>
              <AntForm.Item>
                <Button htmlType="submit" type="primary" loading={isSubmitting} style={{ marginRight: 10 }}>Хадгалах</Button>
                <Button onClick={() => onCancel()}>Болих</Button>
              </AntForm.Item>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
