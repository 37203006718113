import { Document, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import dayjs from 'dayjs';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    fontFamily: "Roboto",
  },
  table: {
    margin: 20,
    display: "flex",
    flexDirection: "column",
    border: "1px solid #000",
    width: "100%",
  },
  header: {
    flexDirection: "row",
    fontSize: 12,
    backgroundColor: "#f3f3f3",
  },
  headerCell: {
    border: "1px solid #000",
    width: "100%", // Adjust width for wrapping
    padding: 5,
    fontSize: 12,
    textAlign: "left",
    flexWrap: 'wrap', // Enable text wrapping
    whiteSpace: 'normal',
  },
  headerCell2: {
    border: "1px solid #000",
    width: "20%", // Adjust width for wrapping
    padding: 5,
    fontSize: 12,
    textAlign: "center",
    flexWrap: 'wrap', // Enable text wrapping
    whiteSpace: 'normal',
  },
  cell: {
    border: "1px solid #000",
    width: "16.67%", // Adjust width for wrapping
    padding: 5,
    fontSize: 10,
    textAlign: "center",
    flexWrap: 'wrap', // Enable text wrapping
    whiteSpace: 'normal',
  },
  row: {
    flexDirection: "row",
  },
});

const PdfDocument = ({ data, operatorName }) => {
  const incomingRow = [];
  const outgoingRow = [];

  if (data.incoming.data.lenght !== 0) {
    data.incoming.data.forEach(el => {
      const headDate = Object.keys(el)[0];
      el[headDate].forEach(e => {
        incomingRow.push(
          {
            date: dayjs(e.date).format("YYYY-MM-DD"),
            system: e.system,
            type: e.type,
            quantity: e.quantity,
            charge: e.charge,
            amount: e.amount
          },
          {
            subHeadDate: dayjs(e.date).format("YYYY-MM-DD") + '-ны нийт гүйлгээ',
            subHeadAmount: e.amount,
          }
        )
      })
    });
  }

  if (data.outgoing.data.lenght !== 0) {
    data.outgoing.data.forEach(el => {
      const headDate = Object.keys(el)[0];
      el[headDate].forEach(e => {
        outgoingRow.push(
          {
            date: dayjs(e.date).format("YYYY-MM-DD"),
            system: e.system,
            type: e.type,
            quantity: e.quantity,
            charge: e.charge,
            amount: e.amount
          },
          {
            subHeadDate: dayjs(e.date).format("YYYY-MM-DD") + '-ны нийт гүйлгээ',
            subHeadAmount: e.amount,
          }
        )
      })
    });
  }

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.table}>
          <View style={styles.header}>
            <View style={styles.headerCell}><Text>Огноо</Text></View>
            <View style={styles.headerCell}><Text>Систем</Text></View>
            <View style={styles.headerCell}><Text>Гүйлгээний төрөл</Text></View>
            <View style={styles.headerCell}><Text>Гүйлгээний тоо</Text></View>
            <View style={styles.headerCell}><Text>Нэхэмжлэх шимтгэл дүн</Text></View>
            <View style={styles.headerCell}><Text>Гүйлгээний нийт дүн</Text></View>
          </View>

          <View style={styles.row}>
            <View style={styles.headerCell}><Text>{operatorName + '- Хүлээн авсан гүйлгээнүүд'}</Text></View>
          </View>

          {incomingRow.map((record, index) => (
            record.subHeadDate ? (
              <View key={`subheader-${index}`} style={styles.row}>
                <View style={styles.headerCell}><Text>{record.subHeadDate}</Text></View>
                <View style={styles.headerCell2}><Text>{record.subHeadAmount}</Text></View>
              </View>
            ) : (
              <View key={`data-${index}`} style={styles.row}>
                <View style={styles.cell}><Text>{record.date}</Text></View>
                <View style={styles.cell}><Text>{record.system}</Text></View>
                <View style={styles.cell}><Text>{record.type}</Text></View>
                <View style={styles.cell}><Text>{record.quantity}</Text></View>
                <View style={styles.cell}><Text>{record.charge}</Text></View>
                <View style={styles.cell}><Text>{record.amount}</Text></View>
              </View>
            )
          ))}

          <View style={styles.row}>
            <View style={styles.headerCell}><Text>{operatorName + ' Нийт хүлээн авсан дүн'}</Text></View>
            <View style={styles.headerCell2}><Text>{data.incoming.totalAmount}</Text></View>
          </View>
          
          <View style={styles.row}>
            <View style={styles.headerCell}><Text>{operatorName + '- Илгээсэн гүйлгээнүүд'}</Text></View>
          </View>

          {outgoingRow.map((record, index) => (
            record.subHeadDate ? (
              <View key={`subheader-${index}`} style={styles.row}>
                <View style={styles.headerCell}><Text>{record.subHeadDate}</Text></View>
                <View style={styles.headerCell2}><Text>{record.subHeadAmount}</Text></View>
              </View>
            ) : (
              <View key={`data-${index}`} style={styles.row}>
                <View style={styles.cell}><Text>{record.date}</Text></View>
                <View style={styles.cell}><Text>{record.system}</Text></View>
                <View style={styles.cell}><Text>{record.type}</Text></View>
                <View style={styles.cell}><Text>{record.quantity}</Text></View>
                <View style={styles.cell}><Text>{record.charge}</Text></View>
                <View style={styles.cell}><Text>{record.amount}</Text></View>
              </View>
            )
          ))}

          <View style={styles.row}>
            <View style={styles.headerCell}><Text>{operatorName + ' Нийт илгээсэн дүн'}</Text></View>
            <View style={styles.headerCell2}><Text>{data.outgoing.totalAmount}</Text></View>
          </View>
        
        </View>
      </Page>
    </Document>
  );
};

export default PdfDocument;
