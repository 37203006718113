import React from "react";
import { Menu } from "antd";
import { NavLink, useLocation } from "react-router-dom";
import {
  BarChartOutlined,
  AimOutlined,
  FileDoneOutlined,
  FileSyncOutlined,
  ApiOutlined,
  ReconciliationOutlined,
} from "@ant-design/icons";
import styled from "styled-components";

const MyMenu = ({ isMobile }) => {
  const location = useLocation();

  const selected =
    (location.pathname.split("/")[1] || "") +
    "-" +
    (location.pathname.split("/")[2] || "");

  const menuItems = [
    {
      key  : "-",
      icon : <BarChartOutlined />,
      label: (
        <>
          Дашбоард <NavLink to="/" />
        </>
      ),
    },
    {
      key  : "connection",
      icon : <ApiOutlined />,
      label: (
        <>
          Систем холболт <NavLink to="/connection" />
        </>
      ),
    },
    {
      key  : "message",
      icon : <AimOutlined />,
      label: (
        <>
          Хүсэлтийн жагсаалт <NavLink to="/message" />
        </>
      ),
    },
    {
      key     : "transaction",
      icon    : <FileDoneOutlined />,
      label   : "Гүйлгээний мэдээлэл",
      children: [
        {
          key  : "transaction-card",
          label: (
            <>
              Карт гүйлгээ <NavLink to="/transaction/card" />
            </>
          ),
        },
        {
          key  : "transaction-account",
          label: (
            <>
              Данс гүйлгээ <NavLink to="/transaction/account" />
            </>
          ),
        },
      ],
    },
    {
      key     : "refund",
      icon    : <FileSyncOutlined />,
      label   : "Буцаалтын мэдээлэл",
      children: [
        {
          key  : "refund-card",
          label: (
            <>
              Карт гүйлгээ <NavLink to="/refund/card" />
            </>
          ),
        },
        {
          key  : "refund-account",
          label: (
            <>
              Данс гүйлгээ <NavLink to="/refund/account" />
            </>
          ),
        },
      ],
    },
    {
      key     : "report",
      icon    : <ReconciliationOutlined />,
      label   : "Тайлан",
      children: [
        {
          key  : "report-monthly_bill",
          label: (
            <>
              Харилцагчийн шимтгэл тооцоо нэхэмжлэх тайлан <NavLink to="/report/monthly_bill" />
            </>
          ),
        },
        {
          key  : "report-reconcilation",
          label: (
            <>
              Баталгаажуулах тайлан <NavLink to="/report/reconcilation" />
            </>
          ),
        },
        {
          key  : "report-net_settlement",
          label: (
            <>
              Хаалтын тайлан <NavLink to="/report/net_settlement" />
            </>
          ),
        },
        {
          key  : "report-successful_trans",
          label: (
            <>
              Амжилттай гүйлгээний тайлан <NavLink to="/report/successful_trans" />
            </>
          ),
        },
        {
          key  : "report-unsuccessful_trans",
          label: (
            <>
              Амжилтгүй гүйлгээний тайлан <NavLink to="/report/unsuccessful_trans" />
            </>
          ),
        },
        {
          key  : "report-monthly-successful_trans",
          label: (
            <>
              Харилцагчийн нийлбэр амжилттай гүйлгээний тайлан
              <NavLink to="/report/monthly/successful_trans" />
            </>
          ),
        },
        {
          key  : "report-monthly-unsuccessful_trans",
          label: (
            <>
              Харилцагчийн нийлбэр амжилтгүй гүйлгээний тайлан
              <NavLink to="/report/monthly/unsuccessful_trans" />
            </>
          ),
        },
      ],
    },
  ];

  return (
    <Container>
      <Menu
        defaultSelectedKeys={[selected]}
        mode="inline"
        theme="light"
        inlineCollapsed={isMobile}
        items={menuItems}
      />
    </Container>
  );
};

export default MyMenu;

const Container = styled.div`
  z-index: 1000;
  padding-top: 20px;
  .project {
    padding: 20px;
    width: 100%;
    &.mobile {
      .ant-btn-group {
        .ant-btn:first-child {
          display: none !important;
        }
      }
    }
    .ant-btn-group {
      width: 100%;
      .ant-btn:last-child {
        width: 39px !important;
      }
      .ant-btn:first-child {
        width: 100%;
      }
    }
  }
  .ant-menu {
    border-right: none;
    width: 100%;

    .ant-menu-item {
      font-size: 14px;
      height: 30px;
      line-height: 30px;
      color: #333;
      font-weight: 400;
      &.ant-menu-item-selected {
        background-color: transparent;
        color: #1890ff;
      }
    }
  }
`;
