import React from "react";
import { Button, DatePicker, Typography } from "antd";
import { PageContainer, PageContent } from "../Layout";
import { Filter } from "../Design";
// import moment from "moment";
import styled from "styled-components";
import { fetchReportData, fetchReportFile } from "../../utils/reportRequest";
import { useSelector } from "react-redux";
import monthlyBillingTable from "../MyTable/monthlyBillingInvoiceTable";
import dayjs from "dayjs";
import PdfDocument from "../pdfTables/monthlyBillingPDF";
import { pdf } from "@react-pdf/renderer";

export default ({ reportType, fileName, reportName, isSuccess }) => {
  const { RangePicker } = DatePicker;
  const { accessToken, user }=useSelector((state) => state.auth);

  const operatorCode=user?.operator?.code||"";
  const operatorName=user?.operator?.name||"Оператор байхгүй";

  const [data, setData]=React.useState();
  const [loading, setLoading]=React.useState(false);

  const [filters, setFilters]=React.useState({
    startDate: null,
    endDate  : null,
  });

  const { Title } = Typography;

  const dateFilter = (e) => {
    if (e) {
			setFilters({
				...filters,
				startDate: e && dayjs(e[0]).startOf("day").format("YYYY-MM-DDTHH:mm"),
				endDate: e && dayjs(e[1]).endOf("day").format("YYYY-MM-DDTHH:mm"),
			});
		} else {
			setFilters({
				...filters,
				startDate: null,
				endDate: null,
			});
		}
  };

  const onClear=() => {
    setFilters({
      startDate: "",
      endDate  : "",
    });
    setData(null);
  };

  const getData = () => {
    setLoading(true);
    fetchReportData("report/monthly_bill", "POST", accessToken, {
      request: {
        pageNo : 1,
        perPage: 5,
        sort: "",
      },
      startDate: filters.startDate ? filters.startDate : "",
      endDate  : filters.endDate ? filters.endDate : "",
      transactionStatus: "SUCCESS",
      operatorCode,
      excel: "false",
    })
      .then((e) => {
        if (e.responseCode) {
          setData(e.data);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log("err ", err);
        setLoading(false);
      });
  };

  React.useEffect(() => {
    if (filters.startDate!==""&&filters.endDate!=="") {
      getData();
    } setData(null);
  }, [filters]);

  const openPDFInNewWindow = async () => {
    const blob = await pdf(
      <PdfDocument
        data={data}
        operatorName={operatorName}
      />
    ).toBlob(); 

    const url = URL.createObjectURL(blob);
    const newWindow = window.open(url);

    if (newWindow) {
      newWindow.focus();
    } else {
      alert("Please allow popups for this website");
    }
  };

  return (
    <PageContainer>
      <Title level={ 2 } style={ { fontSize: "20px", paddingTop: "25px", paddingBottom: "19px" } }>{reportName}</Title>
      <PageContent>
        <Filter
          extra={[
            <RangePicker
              key="RangeDatePicker"
              value={
                filters.startDate && filters.endDate
                  ? [dayjs(filters.startDate), dayjs(filters.endDate)]
                  : null
              }
              onChange={dateFilter}
            />,
            <Button key="ClearButton" onClick={onClear}>Цэвэрлэх</Button>,
            <Button key="dwnldButton"
              style={{ backgroundColor: "green", color: "white" }}
              onClick={() => {
                fetchReportFile(
                  "report/monthly_bill",
                  "POST",
                  accessToken,
                  {
                    request: {
                      pageNo : 1,
                      perPage: 5,
                      sort   : "",
                    },
                    ...filters,
                    operatorCode,
                    transactionStatus: reportType,
                    excel: "true",
                  },
                  `${fileName}_${operatorName}`.toLowerCase()
                );
              }}
            >
              Татах
            </Button>,
            <Button key="PdfButton" onClick={data ? openPDFInNewWindow : null}>Open PDF</Button>
          ]}
        ></Filter>

        {data !== null && (
          <React.Fragment>
            <Table
              initialLoading={loading}
              filters={filters}
              data={data}
              paricipment={operatorName}
              rowKey={(record) => record?.rowKey}
              getData={getData}
              isSuccess={isSuccess}
            />
            <div style={{ height: "30px" }}></div>
          </React.Fragment>
        )}
      </PageContent>
    </PageContainer>
  );
};

const Table=styled(monthlyBillingTable)`
  .ant-table-cell {
    padding: 0px;
    line-height: 15px;
  }
  .ant-table-thead .ant-table-cell {
    padding: 8px;
  }
  .ant-table-summary .ant-table-cell {
    padding: 8px;
  }
  .header-cell {
    text-align: left;
    background-color: #f5f5f5;
    font-weight: bold;
  }
  .subheader-cell {
    padding: 8px;
    padding-left: 40px;
    font-weight: bold;
  }
  .subvalue-cell {
    padding: 8px;
    font-weight: bold;
  }
  .cell-center {
    text-align: center;
  }
`;
