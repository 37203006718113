const initialState = {
  roles           : [],
  permissions     : [],
  arrayPermissions: [],
  s3host          : ""
};

const general = (state = initialState, action ) => {
  switch (action.type) {
    case "general.init": {
      const { roles, permissions, s3host } = action.payload;

      return {
        ...state,
        roles,
        arrayPermissions: permissions,
        permissions     : permissions.reduce((accumulator, iterator) => {
          const reduces = accumulator;

          if (!accumulator.find(i => i.group === iterator.group))
            reduces.push({
              name: iterator.group,
              type: "GROUP"
            });

          reduces.push(iterator);
          return reduces;
        }, []),
        s3host
      };
    }
    default:
      return state;
  }
};

export default general;