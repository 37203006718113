import React from "react";
import { Button, DatePicker } from "antd";
import { PageContainer, PageHeader, PageContent } from "../Layout";
import ReconcilationTable from "../MyTable/reconcilationTable";
import { Filter } from "../Design";
import styled from "styled-components";
import { fetchReportData, fetchReportFile } from "../../utils/reportRequest";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import PdfDocument from "../pdfTables/reconcilationPDF";
import { pdf } from "@react-pdf/renderer";

export default ({ fileName, reportName }) => {
  const { RangePicker } = DatePicker;
  const { accessToken, user } = useSelector((state) => state.auth);

  const ref = React.createRef();

  const [data, setData] = React.useState(null);
  const operatorCode=user?.operator?.code||"";

  const [filters, setFilters] = React.useState({
    startDate: "",
    endDate  : "",
  });

  const dateFilter = (e) => {
    if (e) {
      setFilters({
        ...filters,
        startDate: e && dayjs(e[0]).startOf("day").format("YYYY-MM-DDTHH:mm"),
        endDate: e && dayjs(e[1]).endOf("day").format("YYYY-MM-DDTHH:mm"),
      });
    } else {
      setFilters({
        ...filters,
        startDate: null,
        endDate: null,
      });
    }
  };

  const onClear = () => {
    setFilters({
      startDate: "",
      endDate  : "",
    });
    setData(null);
  };

  const getData = () => {
    if (ref.current != null) {
      ref.current.setLoading(true);
    }
    fetchReportData("report/reconcilation", "POST", accessToken, {
      ...filters,
      operatorCode,
      excel: "false"
    })
      .then((e) => {
        if (e.responseCode) {
          setData(e.data);
        }
        else {
          setData(null);
        }
        if (ref.current != null) {
          ref.current.setLoading(false);
        }
      })
      .catch((err) => {
        console.log("err ", err);
        if (ref.current != null) {
          ref.current.setLoading(false);
        }
      });
  };

  const openPDFInNewWindow = async () => {
    if(data) {
      const blob = await pdf(
        <PdfDocument
          data={data} 
        />
      ).toBlob();

      const url = URL.createObjectURL(blob);
      const newWindow = window.open(url);

      if (newWindow) {
        newWindow.focus();
      } else {
        alert("Please allow popups for this website");
      };
    };
  };

  React.useEffect(() => {
    if (filters.startDate !== "" && filters.endDate !== "") {
      getData();
    } setData(null);
  }, [filters]);


  return (
    <PageContainer>
      <PageHeader title={reportName} key={reportName}/>
      <PageContent>
        <Filter
          extra={[
            <RangePicker 
              value={
                filters.startDate && filters.endDate
                  ? [dayjs(filters.startDate), dayjs(filters.endDate)]
                  : null
              } 
              onChange={dateFilter} 
            />, 
            <Button onClick={onClear}>Цэвэрлэх</Button>,
            <Button
              style={{ backgroundColor: "green", color: "white" }}
              onClick={() => {
                fetchReportFile(
                  "report/reconcilation",
                  "POST",
                  accessToken,
                  {
                    operatorCode,
                    ...filters,
                    excel: "true"
                  },
                  `${fileName}_${user?.operator?.name ?? ""}`.toLowerCase()
                );
              }}
            >
              Татах
            </Button>,
            <Button onClick={data ? openPDFInNewWindow : null}>Open PDF</Button>
          ]}
        ></Filter>

        {data !== null && (
          <React.Fragment>
            <TableContainer>
              <Table
                loading={true}
                filters={filters}
                ref={ref}
                data={data}
                paricipment={user?.operator?.name ?? ""}
                rowKey={(record) => record?.rowKey}
                getData={getData}
              />
            </TableContainer>
          </React.Fragment>
        )}
      </PageContent>
    </PageContainer>
  );
};

const TableContainer = styled.div`
  margin-top: 20px;
  table {
    width: 100%;
    border-collapse: collapse;
  }

  th, td {
    padding: 10px;
    border: 1px solid #ddd;
  }

  th {
    background-color: #f4f4f4;
  }

  /* Print-specific styles */
  @media print {
    th, td {
      font-size: 12px;
      padding: 8px;
    }
    
    body {
      margin: 0;
      padding: 0;
      width: auto;
    }
    
    /* Hide certain elements during print */
    button {
      display: none;
    }

    .filter-container {
      display: none;
    }
  }
`;

const Table = styled(ReconcilationTable)`
  .ant-table-cell {
    padding: 0px;
    line-height: 15px;
  }
  .ant-table-thead .ant-table-cell {
    padding: 8px;
  }
  .ant-table-summary .ant-table-cell {
    padding: 8px;
  }
  .header-cell {
    text-align: left;
    background-color: #f5f5f5;
    font-weight: bold;
  }
  .subheader-cell {
    padding: 8px;
    padding-left: 40px;
    font-weight: bold;
  }
  .subvalue-cell {
    padding: 8px;
    font-weight: bold;
  }
  .cell-center {
    text-align: center;
  }
`;
