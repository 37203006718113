import React from "react";
import { Row, Col, Card, Spin, Statistic } from "antd";
import { dashboard as dashboardApi } from "../../apis";
import styled from "styled-components";
import useFetch from "../../hooks/useFetch";
import { FileDoneOutlined, FileExclamationOutlined, ScanOutlined } from "@ant-design/icons";
import { Line, Pie } from "@ant-design/charts";
import moment from "moment";

const paymentTypeMap = {
  PURCHASE: "Худалдан авалт",
  REFUND  : "Буцаалт",
  TRANSFER: "Шилжүүлэг",
  PAYMENT : "Төлбөр",
};

const paymentMethodMap = {
  BANK_ACCOUNT: "Дансны",
  BANK_CARD   : "Картын"
};

const Stats = () => {
  const [{ refundCount, transactionCount, requestCount, transactionCountByDay, transactionSumByDay, transactionsByPaymentMethod, transactionsByPaymentType }, fetching] = useFetch(dashboardApi.stats)({
    refundCount                : 0,
    transactionCount           : 0,
    requestCount               : 0,
    transactionCountByDay      : [],
    transactionSumByDay        : [],
    transactionsByPaymentType  : [],
    transactionsByPaymentMethod: []
  });

  return (
    <div>
      {/* <Container ismaincontainer="true" title='Статистик'>
        <Spin spinning={fetching}>
          <Row gutter={16}>
            <Col span={8}>
              <Statistic title='Нийт гүйлгээний тоо' value={transactionCount} prefix={<FileDoneOutlined />} />
            </Col>
            <Col span={8}>
              <Statistic title='Нийт хүсэлтийн тоо' value={requestCount} prefix={<ScanOutlined />} />
            </Col>
            <Col span={8}>
              <Statistic title='Нийт буцаалтын тоо' value={refundCount} prefix={<FileExclamationOutlined />} />
            </Col>
          </Row>
        </Spin>
      </Container>
      <br/>
      <Container title='Өдрийн нийт гүйлгээ'>
        <Spin spinning={fetching}>
          <Line
            style={{ width: "100%" }}
            {...{
              data       : transactionCountByDay?.map((r) => ({ ...r, date: moment(r.day).format("YYYY.MM.DD"), total: r.count })),
              xField     : "date",
              yField     : "total",
              isGroup    : true,
              isStack    : true,
              groupField : "name",
              seriesField: "name",
              legend     : { position: "top-left" },
            }}
            height={400}
            forceFit={true}
          />
        </Spin>
      </Container>

      <br/>

      <Container title='Өдрийн нийт гүйлгээний дүн'>
        <Spin spinning={fetching}>
          <Line
            style={{ width: "100%" }}
            {...{
              data       : transactionSumByDay?.map((r) => ({ ...r, date: moment(r.day).format("YYYY.MM.DD"), total: r.sum })),
              xField     : "date",
              yField     : "total",
              isGroup    : true,
              isStack    : true,
              groupField : "name",
              seriesField: "name",
              legend     : { position: "top-left" },
            }}
            height={400}
            forceFit={true}
          />
        </Spin>
      </Container>

      <br/>

      <Row gutter={16}>
        <Col span={12}>
          <Container title='Гүйлгээний төрөл'>
            <Spin spinning={fetching}>
              <Pie
                style={{ width: "100%" }}
                {...{
                  appendPadding: 10,
                  data         : transactionsByPaymentType.map(entry => ({ type: paymentTypeMap[entry.type], value: parseInt(entry.count, 10) })),
                  angleField   : "value",
                  colorField   : "type",
                  radius       : 0.9,
                  label        : {
                    offset : "-30%",
                    content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
                    style  : {
                      fontSize : 14,
                      textAlign: "center",
                    },
                  },
                  interactions: [
                    {
                      type: "element-active",
                    },
                  ],
                }}
                height={300}
                forceFit={true}
              />
            </Spin>
          </Container>
        </Col>
        <Col span={12}>
          <Container title='Гүйлгээний шугам'>
            <Spin spinning={fetching}>
              <Pie
                style={{ width: "100%" }}
                {...{
                  appendPadding: 10,
                  data         : transactionsByPaymentMethod.map(entry => ({ type: paymentMethodMap[entry.payment_method], value: parseInt(entry.count, 10) })),
                  angleField   : "value",
                  colorField   : "type",
                  radius       : 0.9,
                  label        : {
                    offset : "-30%",
                    content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
                    style  : {
                      fontSize : 14,
                      textAlign: "center",
                    },
                  },
                  interactions: [
                    {
                      type: "element-active",
                    },
                  ],
                }}
                height={300}
                forceFit={true}
              />
            </Spin>
          </Container>
        </Col>
      </Row> */}
    </div>
  );
};

const Container = styled(Card)`
  border: 1px solid#e8e8e8;
  border-radius: 5px;
  box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.08);
  margin-left: 5px;
  .ant-tabs-nav {
    border-bottom: none;
  }
  .ant-card-body {
    border-radius: 5px;
    padding: 15px 22px 15px 22px;
  }
`;

export default Stats;
