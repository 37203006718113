import React from "react";
import { Result, Button } from "antd";
import { useNavigate } from "react-router-dom";

const NotfoundPage = () => {
  const navigate = useNavigate();
  return (
    <Result
      status="404"
      title="404"
      subTitle="Уучлаарай!, Хүсэлт олдсонгүй."
      extra={<Button type="primary" onClick={() => navigate("/")}>Нүүр хуудас</Button>}
    />
  );
};

export default NotfoundPage;