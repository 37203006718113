import React from "react";
import { Table } from "antd";
import formatNumber from "../../utils/format";
import stringToFloat from "../../utils/parser";

const ReconcilationTable = (
  {
    paricipment,
    loadData,
    data,
    rowExpandable,
    filters,
    items: rows,
    setHeaderIndexes,
    setSubheaderIndexes,
    perPage,
    getData,
    value,
    el,
    headerIndexes,
    subheaderIndexes,
    initialLoading,
    onResult = () => {},
    ...rest
  },
  ref
) => {
  const [loading, setLoading] = React.useState(initialLoading);
  const [items, setItems] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  // const [tableFilter, setTableFilter] = React.useState({});

  const field = null;
  const sort = null;
  const page = 1;
  const limit = perPage;

  const initialRenderPage = React.useRef(true);
  const initialRenderLimit = React.useRef(true);

  const [tableheaderIdx, setTableheaderIdx] = React.useState(headerIndexes);
  const [tablesubheaderIdx, setTablesubheaderIdx] =
    React.useState(setSubheaderIndexes);

  const reload = React.useCallback(
    async (signal) => {
      if (!loadData) return;
      setLoading(true);

      const res = await loadData(
        {
          filter: {
            query: "",
            ...filters,
          },
          order: {
            [field]: sort,
          },
          offset: {
            page : page,
            limit: limit,
          },
        },
        { signal }
      );

      setItems(
        res.rows.map((row, index) => ({
          ...row,
          rowKey: index + 1,
        }))
      );
      setTotal(res.count);

      if (onResult) onResult(res);

      setLoading(false);
    },
    [filters, limit, loadData, field, sort, page]
  );

  React.useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    reload(signal);

    return () => abortController.abort();
  }, [reload]);

  React.useImperativeHandle(ref, () => ({
    reload() {
      reload();
    },
    setItems() {
      setItems(items);
    },
    getLoading() {
      return loading;
    },
    setLoading(loadStatus) {
      setLoading(loadStatus);
    },
    getPage() {
      return page;
    },
    getLimit() {
      return limit;
    },
  }));

  React.useEffect(() => {
    if (rows) {
      setItems(rows);
      setTotal(rows.length);
    }
  }, [rows]);

  React.useEffect(() => {
    let headerIdx = [];
    let subheaderIdx = [];
    const listData = [];

    if (data != null && data.length > 0) {
      if (data && Array.isArray(data)) {
        let totalOutgoingCount = 0;
        let totalOutgoingAmount = 0;
        let totalIncomingCount = 0;
        let totalIncomingAmount = 0;
        data.forEach((e) => {
          totalOutgoingCount += stringToFloat(e.creditCount);
          totalOutgoingAmount += stringToFloat(e.creditAmount);
          totalIncomingCount += stringToFloat(e.debitCount);
          totalIncomingAmount += stringToFloat(e.debitAmount);
          listData.push(e);
        });
        listData.push({
          paymentMethod: "Нийт гүйлгээ",
          creditCount  : totalOutgoingCount,
          creditAmount : formatNumber(totalOutgoingAmount),
          debitCount   : totalIncomingCount,
          debitAmount  : formatNumber(totalIncomingAmount),
        });
        subheaderIdx.push(listData.length - 1);
        setTableheaderIdx(headerIdx);
        setTablesubheaderIdx(subheaderIdx);
        setItems(listData);
      }
    } else {
      setTableheaderIdx([]);
      setTablesubheaderIdx([]);
      setItems([]);
    }
  }, [data]);

  React.useEffect(() => {
    if (initialRenderPage.current) {
      initialRenderPage.current = false;
    } else {
      getData(el);
    }
  }, [page]);

  React.useEffect(() => {
    if (initialRenderLimit.current) {
      initialRenderLimit.current = false;
    } else {
      getData(el);
    }
  }, [limit]);

  const useHeader = () => {
    const fixNest = (_, index) => {
      if (tableheaderIdx.includes(index)) {
        return 6;
      }
      if (tablesubheaderIdx.includes(index)) {
        return 2;
      }
      return 1;
    };

    const fixNest2 = (_, index) => {
      if (tableheaderIdx.includes(index) || tablesubheaderIdx.includes(index)) {
        return "header-cell";
      }
      return "cell-center";
    };

    const onHeaderCell = (_, index) => {
      let idx = fixNest(_, index);
      let classname = fixNest2(_, index);
      return {
        colSpan  : idx,
        className: classname,
      };
    };

    const onHeaderCell2 = (_, index) => {
      let span = 1;
      let cl = "";
      if (tableheaderIdx.includes(index)) {
        span = 0;
      }
      if (tablesubheaderIdx.includes(index)) {
        span = 1;
        cl = "header-cell";
      }
      return {
        colSpan  : span,
        className: cl,
      };
    };

    const onHeaderCell3 = (_, index) => {
      let span = 1;
      let cl = "";
      if (tableheaderIdx.includes(index)) {
        span = 0;
      }
      if (tablesubheaderIdx.includes(index)) {
        span = 0;
        cl = "header-cell";
      }
      return {
        colSpan  : span,
        className: cl,
      };
    };

    return [
      {
        title : "Гүйлгээний төрөл",
        render: (record) => {
          return <div style={{ padding: "8px" }}>{record.paymentMethod}</div>;
        },
        onCell: onHeaderCell,
      },
      {
        title : "Төлөв",
        render: (record) => {
          return (
            <div style={{ padding: "8px" }}>{record.transactionStatus}</div>
          );
        },
        onCell: onHeaderCell3,
      },
      {
        title   : "Илгээсэн",
        onCell  : onHeaderCell2,
        children: [
          {
            title : "Тоо",
            render: (record) => {
              return <div style={{ padding: "8px" }}>{record.creditCount}</div>;
            },
            onCell: onHeaderCell2,
          },
          {
            title : "Дүн",
            render: (record) => {
              return (
                <div style={{ padding: "8px" }}>{record.creditAmount}</div>
              );
            },
            onCell: onHeaderCell2,
          },
        ],
      },
      {
        title   : "Хүлээн авсан",
        onCell  : onHeaderCell2,
        children: [
          {
            title : "Тоо",
            render: (record) => {
              return <div style={{ padding: "8px" }}>{record.debitCount}</div>;
            },
            onCell: onHeaderCell2,
          },
          {
            title : "Дүн",
            render: (record) => {
              return <div style={{ padding: "8px" }}>{record.debitAmount}</div>;
            },
            onCell: onHeaderCell2,
          },
        ],
      },
    ];
  };

  return (
    <>
      <Table
        {...{
          // scroll    : { x: 100 },
          bordered  : true,
          columns   : useHeader({}),
          dataSource: items,
          rowExpandable,
          pagination: false,
          ...rest,
        }}
        loading={loading}
        rowKey={(record, index) => record?.id ?? index}
      />
    </>
  );
};

export default React.forwardRef(ReconcilationTable);
