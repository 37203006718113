import React from "react";
import { Table } from "antd";
import moment from "moment";
import formatNumber from "../../utils/format";
import stringToFloat from "../../utils/parser";

const NetSettlementTable = (
  {
    paricipment,
    loadData,
    data,
    rowExpandable,
    filters,
    items: rows,
    setHeaderIndexes,
    setSubheaderIndexes,
    perPage,
    getData,
    value,
    el,
    headerIndexes,
    subheaderIndexes,
    onResult = () => {},
    ...rest
  },
  ref
) => {
  const [loading, setLoading] = React.useState(false);
  const [items, setItems] = React.useState([]);
  // const [tableFilter, setTableFilter] = React.useState({});

  const page = 1;
  const limit = perPage;

  const initialRenderPage = React.useRef(true);
  const initialRenderLimit = React.useRef(true);

  React.useState(setSubheaderIndexes);

  const reload = React.useCallback(
    async (signal) => {
      if (!loadData) return;
      setLoading(true);

      const res = await loadData(
        {
          filter: {
            query: "",
            ...filters,
          },
          offset: {
            page : page,
            limit: limit,
          },
        },
        { signal }
      );

      setItems(
        res.rows.map((row, index) => ({
          ...row,
          rowKey: index + 1,
        }))
      );

      if (onResult) onResult(res);

      setLoading(false);
    },
    [filters, limit, loadData, page]
  );

  React.useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    reload(signal);

    return () => abortController.abort();
  }, [reload]);

  React.useImperativeHandle(ref, () => ({
    reload() {
      reload();
    },
    setItems() {
      setItems(items);
    },
    getLoading() {
      return loading;
    },
    setLoading(loadStatus) {
      setLoading(loadStatus);
    },
    getPage() {
      return page;
    },
    getLimit() {
      return limit;
    },
  }));

  React.useEffect(() => {
    if (rows) {
      setItems(rows);
    }
  }, [rows]);
  
  React.useEffect(() => {
    const listData = [];
    if (data != null) {
      let totalDebit = 0;
      let totalCredit = 0;
      listData.push({
        paricipment : paricipment,
        debitAmount : data[0].debitAmount,
        creditAmount: data[0].creditAmount,
      });
      totalDebit += stringToFloat(data[0].debitAmount);
      totalCredit += stringToFloat(data[0].creditAmount);
      listData.push({
        paricipment : "Нийт дүн",
        debitAmount : formatNumber(totalDebit),
        creditAmount: formatNumber(totalCredit),
      });
    }
      setItems(listData);
  }, [data]);

  React.useEffect(() => {
    if (initialRenderPage.current) {
      initialRenderPage.current = false;
    } else {
      getData(el);
    }
  }, [page]);

  React.useEffect(() => {
    if (initialRenderLimit.current) {
      initialRenderLimit.current = false;
    } else {
      getData(el);
    }
  }, [limit]);

  const useHeader = () => {
    const onLastCell = (_, index) => {
      return {
        className: items.length - 1 === index ? "header-cell" : "",
      };
    };

    return [
      {
        title   : "Хаалтын хамрах үе",
        children: [
          {
            title : "Хаалтын оролцогч",
            render: (record) => {
              return <div style={{ padding: "8px" }}>{record.paricipment}</div>;
            },
            onCell: onLastCell,
          },
        ],
      },
      {
        title   : `Эхэлсэн цаг ${filters.startDate && moment(filters.startDate).format("YYYY-MM-DD")}`,
        children: [
          {
            title : "Кредит дүн",
            render: (record) => {
              return <div style={{ padding: "8px" }}>{record.creditAmount}</div>;
            },
            onCell: onLastCell,
          },
        ],
      },
      {
        title   : `Дууссан цаг ${filters.endDate && moment(filters.endDate).format("YYYY-MM-DD")}`,
        children: [
          {
            title : "Дебит дүн",
            render: (record) => {
              return (
                <div style={{ padding: "8px" }}>{record.debitAmount}</div>
              );
            },
            onCell: onLastCell,
          },
        ],
      },
    ];
  };

  return (
    <>
      <Table
        {...{
          bordered  : true,
          columns   : useHeader({}),
          dataSource: items,
          rowExpandable,
          pagination: false,
          ...rest,
        }}
        loading={loading}
        rowKey={(record, index) => record.id || index}
      />
    </>
  );
};

export default React.forwardRef(NetSettlementTable);
