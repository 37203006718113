import React from 'react';
import { Document, Page, Text, View, StyleSheet, Font } from '@react-pdf/renderer';
import source from "../../assets/fonts/Roboto-Regular.ttf";

Font.register({ family: 'Roboto', src: source });

const styles = StyleSheet.create({
  table: {
    display: "table",
    width: "100%",
    padding: 20,
    fontFamily: "Roboto",
  },
  tableRow: {
    flexDirection: "row",
  },
  tableColumn: {
    flexDirection: "column",
  },
  tableCol: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: '#bfbfbf',
  },
  tableSubCol: {
    width: "50%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: '#bfbfbf',
  },
  tableHeader: {
    backgroundColor: '#eeeeee',
    fontWeight: "bold",
  },
  tableHeaderCell: {
    fontSize: 15,
    padding: 5
  },
  tableCell: {
    fontSize: 10,
    padding: 5
  },
});

const PDFTable = ({ data }) => {
  const rows = data ? [
    {
      paymentMethod: data[0].paymentMethod,
      transactionStatus: data[0].transactionStatus,
      creditCount: data[0].creditCount,
      creditAmount: data[0].creditAmount,
      debitCount: data[0].debitCount,
      debitAmount: data[0].debitAmount,
      summary: "Нийт гүйлгээ",
    },
  ] : [];

  return (
    <Document>
      <Page size="A4">
        <View style={styles.table}>
          <View style={[styles.tableRow, styles.tableHeader]}>
            <View style={styles.tableCol}><Text style={styles.tableHeaderCell}>Гүйлгээний төрөл</Text></View>
            <View style={styles.tableCol}><Text style={styles.tableHeaderCell}>Төлөв</Text></View>
            <View style={styles.tableCol}>
              <View>
                <Text style={styles.tableHeaderCell}>Илгээсэн</Text>
                <View style={styles.tableRow}>
                  <View style={styles.tableSubCol}><Text style={styles.tableHeaderCell}>Тоо</Text></View>
                  <View style={styles.tableSubCol}><Text style={styles.tableHeaderCell}>Дүн</Text></View>
                </View>
              </View>
            </View>
            <View style={styles.tableCol}>
              <View>
                <Text style={styles.tableHeaderCell}>Хүлээн авсан</Text>
                <View style={styles.tableRow}>
                  <View style={styles.tableSubCol}><Text style={styles.tableHeaderCell}>Тоо</Text></View>
                  <View style={styles.tableSubCol}><Text style={styles.tableHeaderCell}>Дүн</Text></View>
                </View>
              </View>
            </View>
          </View>
          
          <View style={styles.tableRow} >
            <View style={styles.tableCol}><Text style={styles.tableCell}>{rows[0].paymentMethod}</Text></View>
            <View style={styles.tableCol}><Text style={styles.tableCell}>{rows[0].transactionStatus}</Text></View>
            <View style={[styles.tableCol, styles.tableRow]}>
              <View style={styles.tableSubCol}><Text style={styles.tableCell}>{rows[0].creditCount}</Text></View>
              <View style={styles.tableSubCol}><Text style={styles.tableCell}>{rows[0].creditAmount}</Text></View>
            </View>
            <View style={[styles.tableCol, styles.tableRow]}>
              <View style={styles.tableSubCol}><Text style={styles.tableCell}>{rows[0].debitCount}</Text></View>
              <View style={styles.tableSubCol}><Text style={styles.tableCell}>{rows[0].debitAmount}</Text></View>
            </View>
          </View>

          <View style={styles.tableRow} >
            <View style={styles.tableSubCol}><Text style={styles.tableCell}>{rows[0].summary}</Text></View>
            <View style={[styles.tableCol, styles.tableRow]}>
              <View style={styles.tableSubCol}><Text style={styles.tableCell}>{rows[0].creditCount}</Text></View>
              <View style={styles.tableSubCol}><Text style={styles.tableCell}>{rows[0].creditAmount}</Text></View>
            </View>
            <View style={[styles.tableCol, styles.tableRow]}>
              <View style={styles.tableSubCol}><Text style={styles.tableCell}>{rows[0].debitCount}</Text></View>
              <View style={styles.tableSubCol}><Text style={styles.tableCell}>{rows[0].debitAmount}</Text></View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default PDFTable;
