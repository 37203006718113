import React, { useRef } from "react";
import { Button, DatePicker } from "antd";
import { PageContainer, PageHeader, PageContent } from "../Layout";
import transactionTable from "../MyTable/transactionTable";
import { Filter } from "../Design";
import styled from "styled-components";
import { fetchReportData, fetchReportFile } from "../../utils/reportRequest";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import PdfTable from "../pdfTables/transactionPDF";
import { pdf } from "@react-pdf/renderer";

export default ({ reportType, fileName, reportName, isSuccess }) => {
  const { accessToken, user } = useSelector((state) => state.auth);

  let initPage = 1;
  let initLimit = 5;

  const operatorCode=user?.operator?.code||"";
  const { RangePicker } = DatePicker;

  const disablePagination = false;

  const tableRef=React.createRef();
  const tablePrintRef = useRef();
  const [data, setData] = React.useState(null);

  const [filters, setFilters] = React.useState({
    startDate: "",
    endDate  : "",
  });

  const dateFilter = (e) => {
    if (e) {
      setFilters({
        ...filters,
        startDate: e && dayjs(e[0]).startOf("day").format("YYYY-MM-DDTHH:mm"),
        endDate: e && dayjs(e[1]).endOf("day").format("YYYY-MM-DDTHH:mm"),
      });
    } else {
      setFilters({
        ...filters,
        startDate: null,
        endDate: null,
      });
    }
    setData(null);
    if (tableRef.current != null) {
      tableRef.current.setPage(initPage);
      tableRef.current.setLimit(initLimit);
    }
  };

  const onClear = () => {
    setFilters({
      startDate: "",
      endDate  : "",
    });
    setData(null);
    // setValue([]);
  };

  const getData = (paramPage, paramLimit) => {
    let page = initPage;
    if (paramPage === undefined) {
      if (tableRef.current != null) {
        page = tableRef.current.getPage();
      }
    }

    let limit = disablePagination ? 9999 : initLimit;
    if (paramLimit === undefined) {
      if (tableRef.current != null) {
        limit = tableRef.current.getLimit();
      }
    }
    fetchReportData("report/transaction", "POST", accessToken, {
      request: {
        pageNo : 1,
        perPage: limit,
        sort   : "",
      },
      ...filters,
      transactionStatus: reportType,
      operatorCode     : operatorCode,
      excel: "false"
    })
      .then((e) => {
        if (e.responseCode) {
          setData(e.data);
        }
        if (tableRef.current != null) {
          tableRef.current.setLoading(false);
        }
      })
      .catch((err) => {
        console.log("err ", err);
        if (tableRef.current != null) {
          tableRef.current.setLoading(false);
        }
      });
  };

  const openPDFInNewWindow = async () => {
    const blob = await pdf(
      <PdfTable
        data={data} 
        operatorName={user?.operator?.name ?? ""}
      />
    ).toBlob(); 

    const url = URL.createObjectURL(blob);
    const newWindow = window.open(url);

    if (newWindow) {
      newWindow.focus();
    } else {
      alert("Please allow popups for this website");
    }
  };

  React.useEffect(() => {
    getData(initPage, initLimit);
  }, [filters]);

  return (
    <PageContainer>
      <PageHeader title={reportName} />
      <PageContent>
        <Filter
          extra={[
            <RangePicker
              key="datapicker"
              value={
                filters.startDate && filters.endDate
                  ? [dayjs(filters.startDate), dayjs(filters.endDate)]
                  : null
              } 
              onChange={dateFilter}
            />,
            <Button key="clearbutton" onClick={onClear}>Цэвэрлэх</Button>,
            <Button
              key="downloadbutton"
              style={{ backgroundColor: "green", color: "white" }}
              onClick={() => {
                fetchReportFile(
                  "report/transaction",
                  "POST",
                  accessToken,
                  {
                    request: {
                      pageNo : tableRef.current.getPage(),
                      perPage: tableRef.current.getLimit(),
                      sort   : "",
                    },
                    operatorCode,
                    ...filters,
                    transactionStatus: reportType,
                    excel: "true"
                  },
                  `${fileName}_${user.operator.name}`.toLowerCase()
                )
              }}>
              Татах
            </Button>,
            <Button onClick={data ? openPDFInNewWindow : null}>Open PDF</Button>
          ]}></Filter>

        {data !== null && (
          <TableContainer ref={tablePrintRef}>
            <Table
              ref={tableRef}
              paricipment={user?.operator?.name ?? ""}
              filters={filters}
              data={data}
              perPage={initLimit}
              rowKey={(record) => record?.rowKey}
              getData={getData}
              isSuccess={isSuccess}
              pagination={disablePagination ? false : { pageSize: 10 }}
            />
          </TableContainer>
        )}
      </PageContent>
    </PageContainer>
  );
};

const TableContainer = styled.div`
  margin-top: 20px;
  table {
    width: 100%;
    border-collapse: collapse;
  }

  th, td {
    padding: 10px;
    border: 1px solid #ddd;
  }

  th {
    background-color: #f4f4f4;
  }

  /* Print-specific styles */
  @media print {
    th, td {
      font-size: 12px;
      padding: 8px;
    }
    
    body {
      margin: 0;
      padding: 0;
      width: auto;
    }
    
    /* Hide certain elements during print */
    button {
      display: none;
    }

    .filter-container {
      display: none;
    }
  }
`;

const Table = styled(transactionTable)`
  .ant-table-cell {
    padding: 0px;
    line-height: 15px;
  }
  .ant-table-thead .ant-table-cell {
    padding: 8px;
  }
  .ant-table-summary .ant-table-cell {
    padding: 8px;
  }
  .header-cell {
    text-align: left;
    background-color: #f5f5f5;
    font-weight: bold;
  }
  .subheader-cell {
    padding: 8px;
    padding-left: 40px;
    font-weight: bold;
  }
  .subvalue-cell {
    padding: 8px;
    font-weight: bold;
  }
  .cell-center {
    text-align: center;
  }
`;
