import React from "react";
import {PageContainer,PageContent,PageHeader} from "../../components/Layout";
// import ProcessChart from "./ProcessChart";
import Statistic from "./Statistic";

const Dashboard=() => {
  return (
    <PageContainer>
      <PageHeader title="Дашбоард" />
      {/*<PageContent>
        <Statistic />
      </PageContent>*/}
    </PageContainer>
  );
};

export default Dashboard;